import Navbar from './components/NavBar';
import React from 'react';
import DisplayGrid from './components/DisplayGrid';
import { useIsAuthenticated } from "@azure/msal-react";
import Login from './components/LoginView'
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import "./App.css";
import Map from './components/Map';
import Wishlist from './components/Wishlist';
import WishlistDetail from './components/WishlistDetail';
import { getSuggestedQuery } from '@testing-library/react';
import ManageUsers from './components/ManageUsers';
import Cookies from 'universal-cookie';
function App() {
    const [searchText, setSearchText] = React.useState("")
    const [events, setEvents] = React.useState([])
    const [allEvents, setAllEvents] = React.useState([])
    const [selectedEvent, setSelectedEvent] = React.useState("0")
    const [displays, setDisplays] = React.useState([])
    const [wishlistPacks, setWishlistPacks] = React.useState([{ name: 'myWishlist', client: 'GucciClient', products: [{}], id: "02/03/1995", notes: "Questa è una fantastica wishlist perchè l'ho creata io." },
    { name: 'seconda Wishlist', client: 'BalenciagaClient', products: [{}, {}, {}], id: "02/03/1995", notes: "Questa è un'altra fantastica wishlist perchè l'ho creata io, ha solo la descrizione più dettagliata" }])
    const [currentPage, setCurrentPage] = React.useState(0)
    const [wishlist, setWishlist] = React.useState(false)
    const [map, setMap] = React.useState(false)
    const [manageUsers, setManageUsers] = React.useState(false)
    const [wishlistDetail, setWishlistDetail] = React.useState(false)
    const [selectedWishlist, getWishlistDetail] = React.useState([])
    const [loading, setLoading] = React.useState(true);
    const isAuthenticated = useIsAuthenticated();
    const [areas, setAreas] = React.useState([]);
    const [displayTypes, setDisplayTypes] = React.useState([]);
    const [user, setUser] = React.useState([]);
    //const isAuthenticated = true

    const { instance, inProgress, accounts } = useMsal();

    React.useEffect(() => {
        aquireToken()
    }, [inProgress])


    async function aquireToken() {

        const accessTokenRequest = {

            scopes: ["user.read"],
            account: accounts[0]
        }
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.idToken;
            //console.log("access token: ", accessToken);
            getUser(accessToken)
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            //console.log("access token error:", error);
        })
    }

    async function handleSelectedEvent(selectedEvent) {
        setSelectedEvent(selectedEvent)
        setAreas(selectedEvent.areas)
        setDisplayTypes(selectedEvent.displayTypes)
    }


    async function getUser(token) {
        var myHeaders = new Headers();
        //let token = await aquireToken2();
        myHeaders.append("Authorization", token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };


        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/user", requestOptions)
            .then(response => response.json())
            .then(result => {
                let cookies = new Cookies()
                setUser(result)
                cookies.set('hashCode', result.hashCode, { path: '/', maxAge: 31536000 });
                setTimeout(() => {

                    fetchEvents(token, result)
                }, 150);
            })
            .catch(error => {
                console.log('error', error)
                setLoading(false)
            });

        return res
    }

    async function fetchEvents(accessToken, user) {
        var myHeaders = new Headers();
        let cookies = new Cookies()
        let val = await cookies.get('hashCode')
        myHeaders.append("Authorization", accessToken + val);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let list = []

        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/events", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.length > 0) {
                    setAllEvents(result)
                    setEvents(result)
                    result.map(allEvent => {
                        user.eventIDs.map(e => {
                            if (allEvent.id == e) {
                                if (!list.includes(allEvent)) {
                                    list.push(allEvent)
                                }
                            }
                        })
                    })

                    let parse = list.sort((a, b) => parseFloat(b.timestamp) - parseFloat(a.timestamp))

                    console.log("parse")
                    console.log(parse)

                    setEvents(list)
                    setSelectedEvent(list[0])
                    let selectedEvent = list[0]
                    list.map(event => {
                        if (event.id == selectedEvent.id) {
                            setAreas(event.areas)
                            setDisplayTypes(event.displayTypes)
                        }
                    })
                } else {
                    setSelectedEvent("0")
                }

            })
            .catch(error => {
                console.log('error', error)
                setLoading(false)
            });

        return res
    }


    return (
        isAuthenticated ?
            <React.Fragment>

                {map ? '' : <Navbar setManageUsers={setManageUsers} user={user} setLoading={setLoading} setCurrentPage={setCurrentPage} setWishlistDetail={setWishlistDetail} setWishlist={setWishlist} setMap={setMap} events={events} selectedEvent={selectedEvent} setSelectedEvent={handleSelectedEvent} displays={displays} setDisplays={setDisplays} setSearchText={setSearchText} />
                }   {
                    map ? <Map setLoading={setLoading} setMap={setMap} selectedEvent={selectedEvent} displays={displays} setDisplays={setDisplays} /> : (manageUsers ? <ManageUsers setManageUsers={setManageUsers} events={allEvents} /> : (
                        wishlist
                            ?
                            (wishlistDetail ? <WishlistDetail user={user} loading={loading} setLoading={setLoading} setWishlistDetail={setWishlistDetail} getWishlistDetail={getWishlistDetail} selectedWishlist={selectedWishlist} /> : <Wishlist user={user} setWishlistDetail={setWishlistDetail} setWishlist={setWishlist} loading={loading} setLoading={setLoading} wishlistPacks={wishlistPacks} getWishlistDetail={getWishlistDetail} setWishlistPacks={setWishlistPacks} selectedEvent={selectedEvent} />)
                            :
                            (selectedEvent === '0' && !loading && events.length > 0) || (user.userRole && user.userRole.name !== 'admin') ?
                                <div className='error_message'><p>You are not authorized to access to Showroom Web</p> </div>
                                :
                                <DisplayGrid user={user} setMap={setMap} setWishlist={setWishlist} currentPage={currentPage} setCurrentPage={setCurrentPage} loading={loading} setLoading={setLoading} displays={displays} setDisplays={setDisplays} selectedEvent={selectedEvent} searchText={searchText} areas={areas} displayTypes={displayTypes} events={events}></DisplayGrid>))

                }
            </React.Fragment>
            :
            < Login />
    )
}

export default App;
