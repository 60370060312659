
import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from '@material-ui/core';
import CsvDownloader from 'react-csv-downloader';
import { useMsal } from "@azure/msal-react";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Carousel } from 'react-carousel-minimal';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import LogoutIcon from '@mui/icons-material/Logout';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import Cookies from 'universal-cookie';


//loading function
function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={-26}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="white">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    'z-index': 2
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginRight: 50,
    backgroundColor: 'white'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    fontFamily: 'GucciSans-Bold',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    '&:hover': {
      cursor: 'pointer',
    }
  },
  subtitle: {
    display: 'none',
    fontFamily: 'GucciSans-Book',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function PrimarySearchAppBar(props) {
  const [open, setOpen] = React.useState(false);

  const galleryImages = [
    {
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/GoldenGateBridge-001.jpg/1200px-GoldenGateBridge-001.jpg",
    },
    {
      image:
        "https://cdn.britannica.com/s:800x450,c:crop/35/204435-138-2F2B745A/Time-lapse-hyper-lapse-Isle-Skye-Scotland.jpg",
    },
    {
      image:
        "https://static2.tripoto.com/media/filter/tst/img/735873/TripDocument/1537686560_1537686557954.jpg",
    },
    {
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Palace_of_Fine_Arts_%2816794p%29.jpg/1200px-Palace_of_Fine_Arts_%2816794p%29.jpg",
    },
    {
      image:
        "https://i.natgeofe.com/n/f7732389-a045-402c-bf39-cb4eda39e786/scotland_travel_4x3.jpg",
    },
    {
      image:
        "https://www.tusktravel.com/blog/wp-content/uploads/2020/07/Best-Time-to-Visit-Darjeeling-for-Honeymoon.jpg",
    },
    {
      image:
        "https://www.omm.com/~/media/images/site/locations/san_francisco_780x520px.ashx",
    },
    {
      image:
        "https://images.ctfassets.net/bth3mlrehms2/6Ypj2Qd3m3jQk6ygmpsNAM/61d2f8cb9f939beed918971b9bc59bcd/Scotland.jpg?w=750&h=422&fl=progressive&q=50&fm=jpg",
    },
    {
      image:
        "https://www.oyorooms.com/travel-guide/wp-content/uploads/2019/02/summer-7.jpg",
    },
  ];

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  const [loading, setLoading] = React.useState(true);


  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);
  const [data, setdata] = React.useState([])
  const csvLink = React.useRef()
  const { instance, inProgress, accounts } = useMsal();

  const columns = [
    { displayName: "Display ID", id: "displayID" },
    { displayName: "Display Name", id: "displayName" },
    { displayName: "Display Typology", id: "displayTypology" },
    { displayName: "Display Area", id: "displayArea" },
    { displayName: "Display Photo ID", id: "displayPhotoID" },
    { displayName: "Display ImageLink", id: "displayImageLink" },

    { displayName: "Hotspot ID", id: "hotspotID" },
    { displayName: "Hotspot Name", id: "hotspotName" },

    { displayName: "Product SMC", id: "productSMC" },
    { displayName: "Product SKU", id: "productSKU" },
    { displayName: "Product Name", id: "productName" },
    { displayName: "Product Visibility CW", id: "productVisibilityCW" },
    { displayName: "Product Visibility BE", id: "productVisibilityBE" },
    { displayName: "Product Order", id: "productOrder" },
    { displayName: "Product CreationTime", id: "productCreationTime" },
    { displayName: "Product CreatedBy", id: "productCreatedBy" },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    console.log("handleChange: ")
    console.log(event.target.value)
    props.setSelectedEvent(event.target.value);
  };
  const manageUsers = () => {
    props.setManageUsers(true)
  }
  const handleLogout = () => {
    instance.logout();
  }

  async function aquireToken() {
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0]
    }
    instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
      // Acquire token silent success
      let accessToken = accessTokenResponse.idToken;
      //createCSV(accessToken)
      getCsv(accessToken)
    }).catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect(accessTokenRequest);
      }
      //console.log("access token error:", error);
    })
  }

  async function getCsv(accessToken) {

    props.setLoading(true)

    var myHeaders = new Headers();
    let cookies = new Cookies()
    myHeaders.append("Authorization", accessToken + cookies.get('hashCode'));
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const res = await fetch("https://showroomapp.globalbit.gucci/Prod/csv?eventID=" + props.selectedEvent.id, requestOptions)
      .then(response => response.json())
      .then(result => {
        props.setLoading(false)
        const link = document.createElement('a')
        link.href = result.path
        link.click()
        //setState({ openSuccess: true, openError: false, successMessage: "Wishlist deleted successfully!" });
      })
      .catch(error => {
        console.log('error', error)
        props.setLoading(false)
        //handleOpenDeleteMenu(event, bool)
        //setState({ loading: false, openError: true, openSuccess: false, errorMessage: "Something went wrong." });
      });

    return res
  }

  async function createCSV(accessToken) {
    const csvData = []
    let counter = 0
    var myHeaders = new Headers();
    let cookies = new Cookies()
    myHeaders.append("Authorization", accessToken + cookies.get('hashCode'));
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/displays?id=" + props.selectedEvent.name, requestOptions)
      .then(response => response.json())
      .then(displays => {
        props.setDisplays(displays)
        Promise.all(displays.map(async display => {
          await display.hotspots.forEach(async hotspot => {
            await hotspot.products.forEach(async product => {

              const datas =
              {
                displayID: display.id,
                displayName: display.name + " | " + display.hotspots.map(h => h.name).join('-'),
                displayTypology: display.type,
                displayArea: display.type.area,
                displayPhotoID: display.photoNumber,
                displayImageLink: display.imageLink,

                hotspotID: hotspot.id,
                hotspotName: hotspot.name,

                productSMC: product.smc,
                productSKU: product.sku,
                productName: product.name,
                productVisibilityCW: true,
                productVisibilityBE: true,
                productOrder: product.position,
                productCreationTime: product.timestamp,
                productCreatedBy: product.productCreatedBy
              }
              csvData.push(datas)
            })
          })

        })).then(texts => {
          setdata(csvData)
          csvLink.current.handleClick()
        })
      })
      .catch(error => {
        console.log('error', error)
      });
  }

  async function filterResult(e) {
    props.setSearchText(e.target.value)
    props.setCurrentPage(0)
  }

  async function back() {
    props.setWishlistDetail(false)
    props.setWishlist(false)
    props.setMap(false)
  }

  function formatDate(string) {
    var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    return new Date(parseInt(string) * 1000).toLocaleDateString([], options);
  }

  return (

    <div className={classes.grow}>


      {/*TUTORIAL*/}
      <Dialog
        open={open}
        onClose={handleClose}
        // aria-labelledby="alert-dialog-title"
        // aria-describedby="alert-dialog-description"
        fullScreen
      >


        <AppBar style={{ position: 'fixed', backgroundColor: "black" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>


        <div style={{ marginTop: '0px' }}>

          <Carousel
            data={galleryImages}
            time={2000}
            width="100%"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={false}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            showNavBtn={true}
            style={{
              textAlign: "center",
              maxWidth: "60%",
              margin: "40px auto",
            }}
          />
        </div>

      </Dialog>



      <AppBar
        style={{
          backgroundColor: "black",
        }}
        position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6" onClick={() => back()} noWrap >
            Showroom
          </Typography>
          {props.user && props.user.userRole && props.user.userRole.name === 'admin' ?
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search by room, display name…"
                onChange={e => filterResult(e)}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            :
            null
          }

          {/*  <div className={classes.grow} /> */}


          {/*    <Button
            style={{
              right: "20px",
              color: "white"
            }}
            onClick={handleClickOpen}
            disableElevation>
            Tutorial
          </Button> */}
          {props.user && props.user.userRole && props.user.userRole.name === 'admin' ?
            <div className='right-header'>

              <Box sx={{ width: 30 }}>
                <FormControl fullWidth>
                  <InputLabel className="event_demo" id="demo-simple-select-label gucci-text">Event</InputLabel>
                  <Select
                    style={{
                      right: "20px",
                      color: "white",
                      "background-color": "#000000",
                    }}
                    value={props.selectedEvent}
                    label="Event"
                    onChange={handleChange}
                  >
                    {props.events.length > 0 ?
                      props.events.map((event) => (
                        <MenuItem value={event} className="gucci-text">{event.name}</MenuItem>
                      ))
                      :
                      <MenuItem value='0' className="gucci-text">No events</MenuItem>
                    }
                  </Select>
                </FormControl>
              </Box>

              {/*LOADING*/}
              {progress > 0 && progress < 99 ? <CircularProgressWithLabel color={'white'} value={progress} style={{ marginRight: "30px" }} /> :

                props.events.length > 0 ?
                  <React.Fragment>
                    <Button
                      style={{
                        right: "20px",
                        color: "white",
                        display: "inline-table"
                      }}
                      className="gucci-text"
                      onClick={aquireToken}
                      disableElevation>
                      Export CSV
                      <div style={{ fontSize: "12px" }}>Last edit: {formatDate(props.selectedEvent.lastEdit)}</div>
                    </Button>
                  </React.Fragment>
                  : null
              }



              {props.user && props.user.userRole && props.user.userRole.name === 'admin' ?

                <Button onClick={manageUsers} variant="contained" className={"gucci_text_bold mr-10"} endIcon={<GroupRoundedIcon />}>Manage</Button>

                : ''}
              <Button onClick={() => handleLogout()} className={"gucci-text_bold "} variant="contained" endIcon={<LogoutIcon />}>Logout</Button>

            </div>
            : null}
          <CsvDownloader
            filename="showroom"
            columns={columns}
            datas={data}
            ref={csvLink}
            style={{ display: "none" }}
          />
        </Toolbar>
      </AppBar>
    </div>
  );
}