import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import HotspotGrid from './HotspotGrid'
import Box from '@material-ui/core/Box';
import ImageZoom from 'react-medium-image-zoom'
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import PriorityIcon from '@material-ui/icons/PriorityHighOutlined';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import FilterListIcon from '@material-ui/icons/FilterList';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import TextField from '@mui/material/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Card, CardContent } from '@material-ui/core';
import { CodeSharp, DataUsageRounded, Map } from '@material-ui/icons';
import ScrollContainer from 'react-indiana-drag-scroll';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import AddIcon from '@material-ui/icons/Add';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import ClearIcon from '@mui/icons-material/Clear';
import WorkIcon from '@mui/icons-material/Work';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { transferKeyToUpperCase } from '@aws-amplify/core';
import { AddReactionSharp } from '@mui/icons-material';
import Cookies from 'universal-cookie';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '80px',
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 'auto',
        height: '500px'
    },
    card: {
        padding: theme.spacing(1),

        height: '37px',
        'min-width': '160px',
        fontFamily: 'GucciSans-Bold',
        'margin-right': '10px',
        'margin-left': '2px',
        'margin-top': '8px',
        'box-shadow': '2px 2px #c0bbbb5c',
        'scroll-snap-align': 'center'
    },
    removeFilters: {
        height: '37px',
        'min-width': '150px',
        fontFamily: 'GucciSans-Bold',
        color: "#ca0000",
        'margin-right': '10px',
        'margin-left': '2px',
        'margin-top': '8px',
        'box-shadow': '2px 2px #c0bbbb5c',
        'scroll-snap-align': 'center'
    },
    filterBy: {
        padding: theme.spacing(1),
        height: '37px',
        'min-width': '100px',
        fontFamily: 'GucciSans-Bold',
        'margin-right': '10px',
        'margin-left': '2px',
        'margin-top': '20px',
        'scroll-snap-align': 'center'
    },
    image: {
        width: '100%',
        height: '90%',
        alignItems: 'center',
        justify: 'center'

    },
    img: {
        display: 'block',
        height: '400px',
    },
}));

export default function DisplayGrid(props) {
    const classes = useStyles()


    const [loadingHotspots, setLoadingHotspots] = React.useState(false);

    const [selectedWishlist, setSelectedWishlist] = React.useState("0")
    const [elementsPerPage, setElementsPerPage] = React.useState(10)
    const [byEvent, setEvent] = React.useState("0")
    const [byOrder, setOrder] = React.useState("0")
    const [byArea, setArea] = React.useState("0")
    const [byDisplayType, setDisplayType] = React.useState("0")
    const [numberOfProducts, setNumberOfProducts] = React.useState(0)
    const [value, setValue] = React.useState('all');
    const [open, setOpen] = React.useState(false);
    const [openWishlistDialog, setOpenWishlistDialog] = React.useState(false);
    const [addWishlistDialog, setOpenAddWishlistDialog] = React.useState(false);
    const [openFormDialog, setOpenFormDialog] = React.useState(false);
    const [completed, setCompleted] = React.useState(false);
    const [selectedDisplay, setSelectedDisplay] = React.useState("");
    const [wishlists, setWishlists] = React.useState([]);

    const [noteValue, setNote] = React.useState('');
    const [wishlistName, setWishlistName] = React.useState('');
    const [wishlistClient, setWishlistClient] = React.useState('');

    const [state, setState] = React.useState({
        openSuccess: false,
        openError: false,
        vertical: 'top',
        horizontal: 'right',
        successMessage: '',
        errorMessage: ''
    });
    const { vertical, horizontal, openSuccess, successMessage, openError, errorMessage } = state;

    const { instance, inProgress, accounts } = useMsal();

    const handleClickOpen = (completed, display) => {
        setSelectedDisplay(display.id)
        setCompleted(completed)
        setOpen(true);
    };

    const handleClickDisplayWishlist = (bool, selectedDisplay) => {
        setSelectedDisplay(selectedDisplay)
        setOpenWishlistDialog(bool)
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseWishlistDialog = () => {
        setOpenWishlistDialog(false);
    };

    const handleAddWishlistDialog = (bool) => {
        setOpenAddWishlistDialog(bool);
    };

    const handleOpenFormDialog = (bool) => {
        setOpenFormDialog(bool)
        if (!bool) {
            setWishlistName('')
            setWishlistClient('')
            setValue('')
        }
    }

    const handleChangeNote = (event) => {
        setNote(event.target.value);
    };

    const handleChangeName = (event) => {
        setWishlistName(event.target.value)
    }

    const handleChangeClient = (event) => {
        setWishlistClient(event.target.value)
    }

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        props.setCurrentPage(newPage - 1);
    };

    function renderableItems() {
        const filtered = search(props.searchText)

        if (value === "complete") {
            return filtered.filter(f => f.completed === true)
        } else if (value === "incomplete") {
            return filtered.filter(f => f.completed === false)
        } else {
            return filtered
        }
    }

    function search(event) {

        let toDisplay = []

        props.displays.forEach(function callback(display) {
            let added = false
            if (display.name.toLowerCase().includes(event.toLowerCase())) {
                if (!toDisplay.includes(display)) {
                    toDisplay.push(display)
                    added = true
                }
            }
            if (!added) {
                display.hotspots.forEach(function callback(hotspot) {
                    if (hotspot.name.toLowerCase().includes(event.toLowerCase())) {
                        if (!toDisplay.includes(display)) {
                            toDisplay.push(display)
                            added = true
                        }
                    }
                });
            }
            if (!added) {
                display.hotspots.forEach(function callback(hotspot) {
                    hotspot.products.forEach(function callback(product) {
                        if (product.name.toLowerCase().includes(event.toLowerCase())) {
                            if (!toDisplay.includes(display)) {
                                toDisplay.push(display)
                                added = true
                            }
                        } else if (product.smc.toLowerCase().includes(event.toLowerCase())) {
                            if (!toDisplay.includes(display)) {
                                toDisplay.push(display)
                                added = true
                            }
                        }
                    })
                });
            }
            /*
            if (!added) {
                display.hotspots.forEach(function (hotspot) {
                    hotspot.products.forEach(function (product) {
                        //console.log('smc: ' + product.smc)
                        if (product.smc.toLowerCase().includes(event.toLowerCase())) {
                            if (!toDisplay.includes(display)) {
                                toDisplay.push(display)
                                added = true
                            }
                        }
                    })
                });
            }*/
        })

        return toDisplay
    }

    React.useEffect(() => {
        props.setLoading(true)
        fetchDisplays()
        // getWishlists()
    }, [props.selectedEvent, byOrder, byArea, byDisplayType, byEvent]
    )

    async function getWishlists() {
        console.log("getWishlists displayGrid")
        var myHeaders = new Headers();
        let cookies = new Cookies()
        let accessToken = await aquireToken2();
        myHeaders.append("Authorization", accessToken + cookies.get('hashCode'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }

        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/wishlists", requestOptions)
            .then(response => response.json())
            .then(result => {
                setWishlists(result)
                props.setLoading(false)
            })
            .catch(error => {
                console.log('error', error)
                props.setLoading(false)
            });

        return res
    }

    async function addWishlist() {

        /*
        handleOpenFormDialog(false)
        props.setLoading(true)
 
        var myHeaders = new Headers();
        let cookies=new Cookies()
        let token = await aquireToken2();
 
        myHeaders.append("Authorization", token + cookies.get('hashCode'));
        myHeaders.append("Content-Type", "application/json");
 
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = dd + '/' + mm + '/' + yyyy;
 
        var raw = JSON.stringify({
            id: parseInt(+new Date()/1000).toString(),
            name: wishlistName,
            lastEdit: today,
            note: noteValue,
            client: wishlistClient,
            products: [],
            timestamp: parseInt(+new Date()/1000).toString(),
            mail: '',
        });
 
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        }
 
        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/wishlists", requestOptions)
            .then(response => response.json())
            .then(result => {
                //setState({ openSuccess: true, openError: false, successMessage: "Wishlist added successfully!" });
            })
            .catch(error => {
                console.log('error', error)
                //setState({ loading: false, openError: true, openSuccess: false, errorMessage: "Something went wrong." });
            });
 
        return res
        */
    }

    async function aquireToken2() {

        const accessTokenRequest = {
            scopes: ["user.read"],
            account: accounts[0]
        }
        let accessToken = ''
        await instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            // Acquire token silent success
            accessToken = accessTokenResponse.idToken;
            //console.log("access token: ", accessToken);
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            //console.log("access token error:", error);
        })
        return accessToken
    }

    async function aquireToken() {

        const accessTokenRequest = {
            scopes: ["user.read"],
            account: accounts[0]
        }
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.idToken;
            //console.log("access token: ", accessToken);
            fetchDisplays(accessToken)
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            //console.log("access token error:", error);
        })
    }


    async function fetchDisplays() {

        var myHeaders = new Headers();
        let cookies = new Cookies()
        let token = await aquireToken2();
        myHeaders.append("Authorization", token + cookies.get('hashCode'));
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };




        if (props.selectedEvent && props.selectedEvent.id) {

            let fetchLink = "https://showroomapp.globalbit.gucci/Prod/displays?eventID=" + props.selectedEvent.id

            if (byArea !== "0") {
                fetchLink += "&areaID=" + byArea
            }
            if (byOrder !== "0" && byOrder != "alpha") {
                fetchLink += "&orderBy=" + byOrder
            }
            if (byDisplayType !== "0") {
                fetchLink += "&typeID=" + byDisplayType
            }


            const res = await fetch(fetchLink, requestOptions)
                .then(response => response.json())
                .then(result => {
                    props.setDisplays(result)
                    props.setLoading(false)
                    let counter = 0
                    result.map(display => {
                        display.hotspots.forEach(hotspot => {
                            counter += hotspot.products.length
                        })
                    })
                    setNumberOfProducts(counter)
                    setLoadingHotspots(false)
                    props.setLoading(false)
                    return result
                })
                .catch(error => {
                    console.log('error', error)
                    props.setLoading(false)
                    setLoadingHotspots(false)
                    props.setLoading(false)
                    return error
                });
            return res
        } else {
            // props.setLoading(false)
        }


    }

    async function markDisplay() {
        // const displayToUpdate = await DataStore.query(Display, selectedDisplay);
        // await DataStore.save(Display.copyOf(displayToUpdate, item => {
        //     if (completed) {
        //         item.complete = false
        //     } else {
        //         item.complete = true
        //     }

        // })).then((value) => {
        //     handleClose()
        //     aquireToken()
        // });
    }

    async function addDisplayInWishlist() {
        handleCloseWishlistDialog(true)
        handleAddWishlistDialog(true)
    }

    async function newWishlist() {
        handleAddWishlistDialog(false)
        handleOpenFormDialog(true)
    }

    async function removeFilters() {
        setArea("0")
        setEvent("0")
        setOrder("0")
        setDisplayType("0")
    }

    async function getWishList() {
        props.setWishlist(true)
    }
    async function getMap() {

        props.setMap(true)
    }

    return (
        <div className={classes.root}>
            {props.loading ?
                <div style={{ textAlign: 'center' }}>
                    <Typography style={{ paddingBottom: '20px' }} variant="h6" >Loading...</Typography>
                    <CircularProgress />
                </div>
                :
                <div>

                    <Grid container alignItems="center" justify="center" spacing={2} >
                        <Grid item xs={3} >
                            <div className='flex' >
                                <Paper className={classes.card}>
                                    <div className='data_usage'>
                                        <div className='gucci_text_bold'> Displays: {renderableItems().length}</div>
                                    </div>
                                </Paper>
                                <Paper className={classes.card}>
                                    <div className='data_usage'>
                                        <div className='gucci_text_bold'>  Products: {numberOfProducts}</div>
                                    </div>
                                </Paper>
                            </div>
                            <div className='flex'>
                                <FormControl style={{ display: 'contents', marginTop: '10px', marginBottom: '10px' }} component="fieldset">

                                    <div className="wrapper">
                                        {/*} <FormLabel color='black' component="legend">Show:</FormLabel> */}
                                        <RadioGroup style={{ display: 'contents' }} row aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                                            <FormControlLabel className="option option-1" value="all" control={<Radio color="black" />} label="All" />
                                            <FormControlLabel className="option option-2" value="complete" control={<Radio color="black" />} label="Complete" />
                                            <FormControlLabel className="option option-3" value="incomplete" control={<Radio color="black" />} label="Incomplete" />
                                        </RadioGroup>
                                    </div>


                                    <Select
                                        className='gucci_text select_filter'
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined value-sel"
                                        value={elementsPerPage}
                                        onChange={e => setElementsPerPage(e.target.value)}
                                        label="Element"
                                    >
                                        <MenuItem value="" >
                                        </MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                    </Select>

                                </FormControl>
                            </div>

                            <div className='flex'>
                                <div className={classes.filterBy}>
                                    <div className='gucci_text_bold'>Filter by:</div>
                                </div>
                                <FormControl style={{ minWidth: 200, "margin-top": 5, "margin-right": 10 }}>
                                    <Select
                                        fullWidth
                                        className='gucci_text select_filter'
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined value-sel"
                                        value={byOrder}
                                        onChange={e => setOrder(e.target.value)}
                                        label="Order"
                                    >
                                        <MenuItem value="0" >Select order type</MenuItem>
                                        <MenuItem value="alpha" >Alphabetical</MenuItem>
                                        <MenuItem value="timestamp" >Last modified date</MenuItem>

                                    </Select>
                                </FormControl>
                                <FormControl style={{ minWidth: 200, "margin-top": 5, "margin-right": 10 }}>
                                    <Select
                                        fullWidth
                                        className='gucci_text select_filter'
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined value-sel"
                                        value={byArea}
                                        onChange={e => setArea(e.target.value)}
                                        label="Order"
                                    >
                                        <MenuItem value="0" >Select area</MenuItem>
                                        {props.areas.length > 0 ?
                                            props.areas.map(area =>
                                                <MenuItem value={area.id} >{area.name}</MenuItem>
                                            )
                                            : ''}
                                    </Select>
                                </FormControl>
                                <FormControl style={{ minWidth: 200, "margin-top": 5, "margin-right": 10 }}>
                                    <Select
                                        fullWidth
                                        className='gucci_text select_filter'
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined value-sel"
                                        value={byDisplayType}
                                        onChange={e => setDisplayType(e.target.value)}
                                        label="Order"
                                    >
                                        <MenuItem value="0" >Select display type</MenuItem>
                                        {props.displayTypes.length > 0 ?
                                            props.displayTypes.map(type =>
                                                <MenuItem value={type.id} >{type.name}</MenuItem>
                                            )
                                            : ''}
                                    </Select>
                                </FormControl>
                                <Button className="red" style={{ height: '20px', "margin-top": "30px" }} startIcon={<ClearIcon color='red' />} onClick={() => removeFilters()} />
                            </div>
                        </Grid>

                        <Grid item xs={6} className="right">



                        </Grid>
                        <Grid item xs={2} className="right">
                            {props.selectedEvent.imageUrl ? <Button className='red template_button pl-15 mr-10' startIcon={<Map color='red' />} onClick={() => getMap()} >
                                <div className='gucci_text_bold ml-8 '>  Map</div>
                            </Button>
                                : ''}
                            <Button className='red template_button pl-15' startIcon={<LocalMallIcon color='red' />} onClick={() => getWishList()} >
                                <div className='gucci_text_bold ml-8 '>  Wishlist</div>
                            </Button>

                        </Grid>
                    </Grid >
                    {props.displays.length > 0 ?
                        <React.Fragment>
                            <Pagination style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '5%' }} onChange={handleChangePage} page={props.currentPage + 1} count={Math.ceil(renderableItems().length / elementsPerPage)} />

                            {renderableItems().slice(props.currentPage * elementsPerPage, (props.currentPage + 1) * elementsPerPage).map((display) => (
                                <Grid container alignItems="center" justify="center" spacing={2} >
                                    <Grid item xs={3} >
                                        <Paper className={classes.paper}>

                                            <Typography variant="h6" className='grid summary ml-10' >
                                                <div className='flex center'>
                                                    <Box className='gucci_text_bold' style={{ display: 'inline-block' }} fontWeight="fontWeightBold" >{" " + display.name + " "}</Box>
                                                </div>
                                                <Box className='gucci_text text_center' style={{ display: 'inline-block' }}>  {display.area ? display.area.name : ''}</Box>
                                            </Typography>

                                            <ButtonBase className={classes.image} style={{ height: '72%', 'margin-bottom': '5px' }}>
                                                <ImageZoom
                                                    image={{
                                                        src: display.imageLink400,
                                                        // className: classes.image,
                                                        style: { height: '90%' },
                                                    }}
                                                    zoomImage={{
                                                        src: display.imageLink400,
                                                        alt: ''
                                                    }}
                                                />
                                            </ButtonBase>
                                            <div className='gucci_text_bold grid summary_button'><div style={{ 'margin-bottom': '5px' }}>{display.id} </div>
                                                <Box className='gucci_text' style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                    <div className='flex center'>
                                                        {display.completed ?
                                                            <Button
                                                                onClick={() => handleClickOpen(true, display)}
                                                                disabled={false}
                                                                color="primary"
                                                                startIcon={<CheckIcon />}
                                                                className='complete_button'>
                                                            </Button>
                                                            :
                                                            <Button
                                                                onClick={() => handleClickOpen(false, display)}
                                                                disabled={false}
                                                                color="primary"
                                                                startIcon={<PriorityIcon className='icon' />}
                                                                className='complete_button'>
                                                            </Button>
                                                        }
                                                        {/*!display.completed ?
                                                    <Button
                                                        onClick={() => handleClickDisplayWishlist(true, display)}
                                                        disabled={false}
                                                        color="primary"
                                                        startIcon={<StarOutlineIcon />}
                                                        className='complete_button'>
                                                    </Button>
                                                    :
                                                    <Button
                                                        onClick={() => handleClickDisplayWishlist(false, display)}
                                                        disabled={false}
                                                        color="primary"
                                                        startIcon={<StarIcon className='icon' />}
                                                        className='complete_button'>
                                                    </Button>
                                            */}
                                                    </div>
                                                </Box>
                                            </div>
                                        </Paper>

                                    </Grid>
                                    <Grid item xs={8}>

                                        <Paper className={classes.paper}>
                                            <ScrollContainer className="slider"  >
                                                <HotspotGrid
                                                    user={props.user}
                                                    fetchDisplays={aquireToken}
                                                    loadingHotspots={loadingHotspots}
                                                    setLoadingHotspots={setLoadingHotspots}
                                                    setDisplays={props.setDisplays}
                                                    searchText={props.searchText}
                                                    value={value}
                                                    currentPage={props.currentPage}
                                                    display={display}
                                                    displays={props.displays}
                                                    setLoading={props.setLoading}
                                                ></HotspotGrid></ScrollContainer>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            ))
                            }

                            < Pagination style={{ marginTop: '30px', marginBottom: '30px', marginLeft: '5%', fontFamily: 'GucciSans-Bold' }} onChange={handleChangePage} page={props.currentPage + 1} count={Math.ceil(renderableItems().length / elementsPerPage)} />
                        </React.Fragment>

                        :
                        <div className='error_message'><p>No results for applied filters.</p> </div>}


                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Do you want to mark this display as"} {completed ? "incomplete?" : "complete?"}</DialogTitle>
                        <DialogContent>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                No
                            </Button>
                            <Button onClick={() => markDisplay()} color="primary" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>


                    {/* */}
                    <Dialog
                        open={openWishlistDialog}
                        onClose={handleCloseWishlistDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Do you want to "} {completed ? "remove" : "insert"} {" all display products "} {completed ? "from" : "in"} {" wishlist?"}
                        </DialogTitle>
                        <DialogContent>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseWishlistDialog} color="primary" className="red">
                                No
                            </Button>
                            <Button onClick={() => addDisplayInWishlist()} color="primary" className="red" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>


                    <Dialog
                        open={addWishlistDialog}
                        onClose={handleAddWishlistDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Add products to your wishlist
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Select the wishlist to add the products to
                            </DialogContentText>
                            <Select
                                fullWidth
                                className='gucci_text'
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined value-sel"
                                value={selectedWishlist}
                                onChange={e => setSelectedWishlist(e.target.value)}
                                label="Select a wishlist"
                            >
                                <MenuItem value="0" >Select a wishlist</MenuItem>
                                {wishlists.length > 0 ?

                                    wishlists.map(w =>
                                        <MenuItem value={w.id}>
                                            {w.name}
                                        </MenuItem>
                                    )
                                    :
                                    <MenuItem value={''}>
                                        No wishlists
                                    </MenuItem>
                                }
                            </Select>
                            <br />
                            <br />
                            <DialogContentText>
                                or create a
                                <Button onClick={() => newWishlist()} className="red">
                                    new wishlist
                                </Button>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleAddWishlistDialog(false)} className="red" color="primary">
                                cancel
                            </Button>
                            <Button onClick={() => addDisplayInWishlist()} className="red" color="primary" autoFocus>
                                add
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openFormDialog}
                        onClose={() => handleOpenFormDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth="sm"
                    >
                        <DialogTitle id="alert-dialog-title"><Typography className="gucci_text_bold" variant="h5" >
                            New wishlist
                        </Typography></DialogTitle>
                        <DialogContent>
                            <TextField id="wishlist_name" label="Name your wishlist" variant="standard" onChange={(e) => { handleChangeName(e) }} />
                            <div id='client_new_wishlist_dialog'>
                                <TextField id="wishlist_client" label="Client name" variant="standard" onChange={(e) => { handleChangeClient(e) }} />
                            </div>
                            <div id='notes_new_wishlist_dialog'>
                                <TextField
                                    id="standard-multiline-flexible"
                                    label="Notes"
                                    multiline
                                    maxRows={4}
                                    value={noteValue}
                                    onChange={handleChangeNote}
                                    variant="standard"
                                    fullWidth
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleOpenFormDialog(false)} className="template_button">
                                Cancel
                            </Button>
                            <Button onClick={() => addWishlist()} className="red template_button" autoFocus>
                                Create
                            </Button>
                        </DialogActions>
                    </Dialog>


                </div >

            }
        </div >
    );
}
