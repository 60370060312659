import React, { useRef } from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

import { Box, Button, CircularProgress, Grid, IconButton, makeStyles, Paper, Select, Typography } from "@material-ui/core";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AddIcon from '@material-ui/icons/Add';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@mui/material/TextField';
import { AccountCircle, ExitToApp } from '@material-ui/icons';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { iconButtonClasses } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';

const useStyles = makeStyles((theme) => ({
    root: {
        'margin-top': '95px',
        'margin-left': '25px',
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'grid',
        margin: '15px',
        width: '95%',
        height: '-webkit-available'
    }
}));

export default function Wishlist(props) {
    const classes = useStyles()
    const inputEl = useRef(iconButtonClasses);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openFormDialog, setOpenFormDialog] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [wishlistName, setWishlistName] = React.useState('');
    const [wishlistClient, setWishlistClient] = React.useState('');
    const { instance, inProgress, accounts } = useMsal();
    const [loading, setLoading] = React.useState(true);
    const [wishlists, setWishlists] = React.useState([]);
    const [state, setState] = React.useState({
        openSuccess: false,
        openError: false,
        vertical: 'top',
        horizontal: 'right',
        successMessage: '',
        errorMessage: ''
    });
    const { vertical, horizontal, openSuccess, successMessage, openError, errorMessage } = state;



    const handleClose1 = (e) => {
        setAnchorEl(null);
        e.stopPropagation()
    };

    const handleClose = () => {
        setState({ ...state, openSuccess: false, openError: false });
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    React.useEffect(() => {
        aquireToken()
    }, [props.selectedEvent])


    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleChangeName = (event) => {
        setWishlistName(event.target.value)
    }

    const handleChangeClient = (event) => {
        setWishlistClient(event.target.value)
    }

    async function back() {
        props.setWishlist(false)
    }
    async function next(wishlist) {

        props.setWishlistDetail(true)
        props.getWishlistDetail(wishlist)
        //  props.setWishlist(false)
    }

    const handleOpenFormDialog = (bool) => {
        setOpenFormDialog(bool)
        if (!bool) {
            setWishlistName('')
            setWishlistClient('')
            setValue('')
        }
    }

    const handleOpenDeleteMenu = (event, bool, wishlist) => {
        event.stopPropagation()
        setOpenDeleteDialog(bool)
    }
    const handleClick1 = (index, event) => {
        event.stopPropagation()
        setAnchorEl({ [index]: event.currentTarget });
    };


    async function getWishlist(accessToken) {
        setLoading(true)
        var myHeaders = new Headers();
        let cookies = new Cookies()
        myHeaders.append("Authorization", accessToken + cookies.get('hashCode'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }

        console.log("selectedEvent")
        console.log(props.selectedEvent)

        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/wishlists?eventID=" + props.selectedEvent.id, requestOptions)
            .then(response => response.json())
            .then(result => {
                //setWishlists(prevWishlists => ([...prevWishlists, ...result]))
                setWishlists(result)


                setLoading(false)
                //handleOpenFormDialog(false)
                //setState({ openSuccess: true, openError: false, successMessage: "Wishlist added successfully!" });
                //setEvents(result)
                //setSelectedEvent(result[result.length - 1])
            })
            .catch(error => {
                console.log('error', error)
                setLoading(false)
                //handleOpenFormDialog(false)
                //setState({ openError: true, openSuccess: false, errorMessage: "Something went wrong." });
            });

        return res
    }

    function getWishListPacks() {
        let wishlistPacks = 'Nessuna wishlist presente'
        if (wishlists.length > 0)
            wishlistPacks = wishlists.map((w, index) =>
                <div className="mt-15">
                    {/**/}<Button key={w.id} className=' template_button_wishlist pl-15' /*endIcon={<ArrowForwardIosIcon className='red' />}*/ onClick={() => next(w)}>
                        <div className="grid w-99">
                            <div className='flex wishlist_title '>
                                <div className='gucci_text_bold ml-8 capitalize left wishlist_name'> {w.name}| {w.products.length}</div>
                                <div className='hotspot_button wishlist_vert_icon' key={w.id}>
                                    <Button
                                        ref={inputEl}
                                        aria-owns={openDeleteDialog ? 'menu-appbar' : null}
                                        aria-haspopup="true"
                                        onClick={(e) => handleClick1(index, e)}
                                        color="primary"
                                        className='complete_button'
                                    >
                                        <MoreVertIcon />
                                    </Button>
                                    <Menu
                                        anchorEl={
                                            // Check to see if the anchor is set.
                                            anchorEl && anchorEl[index]
                                        }
                                        keepMounted
                                        open={
                                            // Likewise, check here to see if the anchor is set.
                                            Boolean(anchorEl && anchorEl[index])
                                        }
                                        onClose={(e) => handleClose1(e)}
                                        id="menu-appbar"
                                        className='menu_personale'
                                    >
                                        <MenuItem onClick={event => deleteWishlist(event, false, w)}>Delete Wishlist</MenuItem>

                                    </Menu>
                                    {/*}
                                    <Button
                                        aria-controls="delete-menu"
                                        aria-haspopup="true"
                                        onClick={event => handleOpenDeleteMenu(event, true, w)}
                                        color="primary"
                                        className='complete_button'
                                        startIcon={<MoreVertIcon />}>
                                    </Button>
                                    <Menu

                                        id="delete-menu"
                                        anchorEl={openDeleteDialog}
                                        keepMounted
                                        open={Boolean(openDeleteDialog)}
                                        onClose={(event) => handleOpenDeleteMenu(event, false)}
                                    >
                                        <MenuItem onClick={event => deleteWishlist(event, false, w)}>Delete Wishlist</MenuItem>
    </Menu>*/}
                                    <div>

                                    </div>

                                </div>
                            </div>
                            <div className="grid left wishlist_details">
                                <div className='gucci_text ml-8 capitalize '> Client: {w.client}</div>
                                <div className='gucci_text ml-8 capitalize'> Creation Date: {w.lastEdit}</div>
                            </div>
                        </div>


                    </Button >
                </div >
            )

        return wishlistPacks
    }

    async function aquireToken() {

        var token = ''
        const accessTokenRequest = {

            scopes: ["user.read"],
            account: accounts[0]
        }

        await instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.idToken;
            getWishlist(accessToken)

            token = accessToken
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
        })

        return token
    }

    async function aquireToken2() {

        var token = ''
        const accessTokenRequest = {

            scopes: ["user.read"],
            account: accounts[0]
        }

        await instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.idToken;
            token = accessToken
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
        })

        return token
    }

    async function addWishlist() {

        console.log("addWishlist")
        handleOpenFormDialog(false)
        setLoading(true)

        var myHeaders = new Headers();
        let token = await aquireToken2();
        let cookies = new Cookies()
        myHeaders.append("Authorization", token + cookies.get('hashCode'));
        myHeaders.append("Content-Type", "application/json");

        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = dd + '/' + mm + '/' + yyyy;

        let generatedId = uuidv4()

        var raw = JSON.stringify({
            id: '',
            eventID: props.selectedEvent.id,
            name: wishlistName,
            lastEdit: today,
            client: wishlistClient,
            products: [],
            timestamp: parseInt(+new Date() / 1000).toString()
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        }

        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/wishlists", requestOptions)
            .then(response => response.json())
            .then(result => {
                getWishlist(token)
                setState({ openSuccess: true, openError: false, successMessage: "Wishlist added successfully!" });
            })
            .catch(error => {
                console.log('error', error)
                setState({ loading: false, openError: true, openSuccess: false, errorMessage: "Something went wrong." });
            });

        return res
    }

    async function deleteWishlist(event, bool, wishlist) {
        console.log("deleteWishlist")
        event.stopPropagation()
        setAnchorEl(false)
        setLoading(true)
        let token = await aquireToken2()

        var myHeaders = new Headers();
        let cookies = new Cookies()
        myHeaders.append("Authorization", token + cookies.get('hashCode'));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            id: wishlist.id,
        });

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        }

        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/wishlists", requestOptions)
            .then(response => response.json())
            .then(result => {
                handleOpenDeleteMenu(event, bool)
                getWishlist(token)
                setState({ openSuccess: true, openError: false, successMessage: "Wishlist deleted successfully!" });
            })
            .catch(error => {
                console.log('error', error)
                handleOpenDeleteMenu(event, bool)
                setState({ loading: false, openError: true, openSuccess: false, errorMessage: "Something went wrong." });
            });

        return res
    }

    return (


        <div className={classes.root}>
            {loading ?
                <div style={{ textAlign: 'center' }}>
                    <Typography style={{ paddingBottom: '20px' }} variant="h6" >Loading...</Typography>
                    <CircularProgress />
                </div>
                :
                <div>
                    <Grid item xs={2} className="ml-20" >
                        <Button className='red template_button pl-15' startIcon={<ArrowBackIosNewIcon color='red' />} onClick={() => back()} >
                            <div className='gucci_text_bold ml-8 '>  Back</div>
                        </Button>
                    </Grid>

                    <div className="gucci_text_bold mt-8 ml-8">
                        <Grid container alignItems="center" justify="center"  >
                            <Grid item xs={12} >
                                <Paper className={classes.paper}> <div className="flex center_align">
                                    <div className="flex center_align">
                                        <LocalMallIcon className="wishlist_icon" />
                                        <Typography className="gucci_text_bold" variant="h5" >
                                            Wishlists
                                        </Typography>
                                    </div>
                                    <div className='hotspot_button left_align'>
                                        <Button
                                            onClick={() => handleOpenFormDialog(true)}
                                            color="primary"
                                            className='complete_button'
                                            startIcon={<AddIcon />}>
                                        </Button>
                                    </div>
                                </div>
                                    <div className="mt-15 flex wrap"> {getWishListPacks()} </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>

                    <Dialog
                        open={openFormDialog}
                        onClose={() => handleOpenFormDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth="sm"
                    >
                        <DialogTitle id="alert-dialog-title"><Typography className="gucci_text_bold" variant="h5" >
                            New wishlist
                        </Typography></DialogTitle>
                        <DialogContent>
                            <TextField id="wishlist_name" label="Name your wishlist" variant="standard" onChange={(e) => { handleChangeName(e) }} />
                            <div id='client_new_wishlist_dialog'>
                                <TextField id="wishlist_client" label="Client name" variant="standard" onChange={(e) => { handleChangeClient(e) }} />
                            </div>
                            {/* 
                            <div id='notes_new_wishlist_dialog'>
                                <TextField
                                    id="standard-multiline-flexible"
                                    label="Notes"
                                    multiline
                                    maxRows={4}
                                    value={value}
                                    onChange={handleChange}
                                    variant="standard"
                                    fullWidth
                                />
                            </div>
                            */}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleOpenFormDialog(false)} className="template_button">
                                Cancel
                            </Button>
                            <Button onClick={() => addWishlist()} className="red template_button" autoFocus>
                                Create
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*
                    <Dialog
                        open={openDeleteDialog}
                        onClose={(event) => handleOpenDeleteMenu(event, false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth="sm"
                    >
                        <DialogTitle id="alert-dialog-title"></DialogTitle>
                        <DialogContent>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={(event) => handleOpenDeleteMenu(event, false)} className="template_button">
                                Back
                            </Button>
                            <Button onClick={() => addWishlist()} className="red template_button" autoFocus>
                                Create
                            </Button>
                        </DialogActions>
                    </Dialog> */}



                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'rigth',
                        }}
                        open={openSuccess}
                        autoHideDuration={3000}
                        onClose={handleClose}
                        message="I love snacks"
                        key={vertical + horizontal}
                    >
                        <Alert onClose={handleClose} severity="success" dx={{ width: '300px' }}>
                            {successMessage}
                        </Alert>
                    </Snackbar>

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'rigth',
                        }}
                        open={openError}
                        onClose={handleClose}
                        autoHideDuration={3000}
                        message=""
                        key={{
                            vertical: 'bottom',
                            horizontal: 'rigth',
                        }}
                    >
                        <Alert onClose={handleClose} severity="error" dx={{ width: '300px' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>

                </div>}

        </div>




    )
}