import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Papa from 'papaparse'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import Cookies from 'universal-cookie';


const ImportLook = (props) => {
    const [looks, setLooks] = useState([]);
    const [products, setProducts] = useState([]);
    const [importing, setImporting] = useState(false);
    const { instance, inProgress, accounts } = useMsal();

    React.useEffect(() => {
        //parseCsv()
    }, [])

    /*
    function parseCsv() {
        var looks = []
        var producs = []

        Papa.parse('https://showroom.globalbit.gucci/NOTDeletethisFolder/look.csv', {
            header: true,
            delimiter: ",",
            download: true,
            dynamicTyping: true,
            complete: function (results) {

                if (results.errors != null) {
                    //console.log("some errors while parsing csv", results.errors)
                }
                if (results.data != null) {
                    //console.log("csv successfully parsed", results.data)

                    results.data.forEach(row => {

                        if (row.LOOK != null && row.SMC != null) {
                            producs.push(row)

                            if (!looks.some(e => e.LOOK === row.LOOK)) {
                                looks.push(row)
                            }

                        } else {
                            // console.log("empty row")
                        }
                    })
                    setLooks(looks)
                    setProducts(producs)
                }
            }
        });


    }
*/


    async function aquireToken(look) {
        const accessTokenRequest = {
            scopes: ["user.read"],
            account: accounts[0]
        }
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.idToken;
            importProducts(look, accessToken)
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            //console.log("access token error:", error);
        })
    }

    async function importProducts(look, accessToken) {
        setImporting(true)
        const res = products.filter(item => item.LOOK === look)

        Promise.all(res.map(async row => {
            let formattedSMC = row.SMC.slice(0, 6) + "-" + row.SMC.slice(6, 11) + "-" + row.SMC.slice(11, 15)
            let imgLink = "http://getimage.emea.guccigroup.dom/getimage/?Cod=" + formattedSMC

            const newItem = {
                id: generateUniqSerial(),
                name: "N/A",
                hotspostID: props.hotspot.id,
                sku: "N/A",
                smc: row.SMC,
                imageLink: imgLink,
                timestamp: parseInt(+new Date() / 1000).toString(),
                createdBy: "",
                visibilityColorWeb: true,
                visibilityBrandExperience: true,
                position: ""
            }

            var myHeaders = new Headers();
            let cookies = new Cookies()
            myHeaders.append("Authorization", accessToken + cookies.get('hashCode'));
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify(newItem);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/products", requestOptions)
                .then(response => response.json())
                .then(result => {
                    // console.log(result)
                })
                .catch(error => console.log('error', error));

        })).then(texts => {
            setImporting(false)
            props.setOpenDialogImportLook(false)
            props.fetchDisplays()
        })


    }


    function generateUniqSerial() {
        return 'xxxx-xxxx-xxx-xxxx'.replace(/[x]/g, (c) => {
            const r = Math.floor(Math.random() * 16);
            return r.toString(16);
        });
    }


    return (
        <Dialog open={props.openDialogImportLook} onClose={() => props.setOpenDialogImportLook(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Import a Look</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Import a Look in the Hotspot named: {props.hotspot.name}
                </DialogContentText>

                {importing ? <CircularProgress /> :

                    looks.map((item) => (
                        <ListItem onClick={() => aquireToken(item.LOOK)} title={item.LOOK} button>
                            <ListItemText primary={item.LOOK} />
                        </ListItem>
                    ))

                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOpenDialogImportLook(false)} className="red template_button">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>

    );
};

export default ImportLook
