import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Menu, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { Add, ArrowForwardIos, Check, Close, MoreVert, PersonAdd, Save, Toc } from '@material-ui/icons';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import React, { useRef } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InputLabel from '@mui/material/InputLabel';
import EventIcon from '@mui/icons-material/Event';
import { fade, makeStyles } from '@material-ui/core/styles';
import { Alert, Snackbar } from '@mui/material';
import { CheckBox } from '@mui/icons-material';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Cookies from 'universal-cookie';

const useStyles = makeStyles((theme) => ({
    root: {
        'margin-top': '95px',
        'margin-left': '25px',
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'grid',
        margin: '15px',
        width: '95%',
        height: '-webkit-available'
    }
}));
export default function ManageUsers(props) {
    const classes = useStyles()
    const [eventsList, setEvents] = React.useState([]);
    const [selectedEvent, setSelectedEvent] = React.useState(0);
    const [selectedEventLabel, setSelectedEventLabel] = React.useState('Select Event...');
    const [roles, setRoles] = React.useState([]);
    const [selectedRole, setSelectedRole] = React.useState(0);
    const [selectedRoleLabel, setSelectedRoleLabel] = React.useState();
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [addingEmail, setAddingEmail] = React.useState('');
    const [addingRole, setAddingRole] = React.useState({
        id: '0', name: 'Select Role...'
    });
    const [addingEvents, setAddingEvents] = React.useState(["e9e8e105-d585-48e0-a9c4-4fe323688629"]);
    const [editingEvents, setEditingEvents] = React.useState([]);
    const [editingEmail, setEditingEmail] = React.useState('');
    const [editingRole, setEditingRole] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');
    const [is_default, setIsDefault] = React.useState(false);
    const [openMenu, setOpenMenu] = React.useState(false);
    const [openEdit, setEditMenu] = React.useState(false);
    const [editView, setEditView] = React.useState(false);
    const [addView, setAddView] = React.useState(false);
    const [openEventsDialog, setEventsDialog] = React.useState(false);
    const [users, setUsers] = React.useState([]);
    const { instance, inProgress, accounts } = useMsal();
    const inputEl = useRef(Button);
    const [toDelete, setToDelete] = React.useState({})
    const [deleteConfirmation, setDeleteConfirmation] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showSelect, setShowSelect] = React.useState(false);
    const [eventsUserList, setEventsUserList] = React.useState([]);
    const [eventsUserListIDs, setEventsUserListIDs] = React.useState([]);
    const [selectedEventDialog, setSelectedEventDialog] = React.useState(0);
    const [selectedEventDialogLabel, setSelectedEventDialogLabel] = React.useState('Select Event...');
    const [loadingDialog, setLoadingDialog] = React.useState(false);

    /* gestione manage roles */
    const [manageRole, setManageRole] = React.useState(true);
    const [loadingManageRoles, setLoadingManageRoles] = React.useState(false);
    const [selectedManageRole, setSelectedManageRole] = React.useState(0);
    const [selectedManageRoleLabel, setSelectedManageRoleLabel] = React.useState();
    const [listCheckedCheckbox, setListCheckedCheckbox] = React.useState([]);
    const [isDefaultRole, setAsDefault] = React.useState(false);
    const [isDefaultRoleChanged, setDefaultRoleChanged] = React.useState(false);
    const [openConfirmDialog, setConfirmDialog] = React.useState(false);




    const [state, setState] = React.useState({
        openSuccess: false,
        openError: false,
        vertical: 'top',
        horizontal: 'right',
        successMessage: '',
        errorMessage: ''
    });
    const { vertical, horizontal, openSuccess, successMessage, openError, errorMessage } = state;
    async function back() {
        props.setManageUsers(false)
    }

    React.useEffect(() => {
        getAllUsers()
        getAllUsersRoles()
        //  setEvents([{ id: 0, name: 'Select Event...' }, { id: 1, name: 'evento1' }, { id: 2, name: 'evento2' }, { id: 3, name: 'evento3' }, { id: 4, name: 'evento4' }])
        // setRoles([{ id: 0, name: 'Select Role...' }, { id: 1, name: 'admin' }, { id: 2, name: 'showroomAssistant' }, { id: 3, name: 'MappingStacff' }])
        let res = []
        res.push({
            id: 0,
            name: 'Select Event...'
        })
        //  console.log('gli eventi sono ' + props.events)
        props.events.map((e) => {
            res.push(e)
        })
        setEvents(res)
    }, [props.events])
    async function getToken() {
        var token = ''
        const accessTokenRequest = {

            scopes: ["user.read"],
            account: accounts[0]
        }

        await instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.idToken;

            token = accessToken
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
        })

        return token
    }
    async function getUsers(accessToken) {
        setLoading(true)
        var myHeaders = new Headers();
        let cookies = new Cookies()
        myHeaders.append("Authorization", accessToken + cookies.get('hashCode'));
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/user/all", requestOptions)
            .then(response => response.json())
            .then(result => {
                let tmp = []
                result.map((u) => {
                    tmp.push(u)
                })
                setUsers(tmp)
                setLoading(false)

            })
            .catch(error => {
                console.log('error', error)
                setLoading(false)

            });

    }

    async function getUserRoles(accessToken) {
        var myHeaders = new Headers();
        let cookies = new Cookies()
        myHeaders.append("Authorization", accessToken + cookies.get('hashCode'));
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/user/role", requestOptions)

            .then(response => response.json())
            .then(result => {
                let selectRole = {
                    id: '0',
                    name: 'Select Role...',
                    eventsIDs: ['0'],
                    isDefault: false
                }
                result.push(selectRole)
                setRoles(result)
                setTimeout(() => {


                    setLoading(false)
                }, 200);
            })
            .catch(error => {
                console.log('error', error)
                setLoading(false)

            });

    }
    const setEdit = (u, val) => {
        setAnchorEl(null);
        users.map((us) => {
            if (us.id === u.id) {
                us.editView = true
            }
            else { us.editView = false }
        })
        setSelectedUser(u)
        setEditingEmail(u.email)
        if (u.userRole)
            setEditingRole(u.userRole)
        else {
            setEditingRole({
                id: '0',
                name: 'Select Role...',
                eventsIDs: ['0'],
                isDefault: true
            })
        }
        setEditingEvents(u.eventIDs)
        setEditView(val)
        setIsDefault(u.userRole ? false : true)
        setEventsUserList(u.events)
        setEventsUserListIDs(u.eventIDs ? u.eventIDs : [])
    }

    const deleteConfirm = (u) => {
        setToDelete(u)
        setDeleteConfirmation(true)
    }
    async function deleteUser(u) {

        let toDelete = {
            id: u.id
        }
        setLoading(true)
        var myHeaders = new Headers();
        let token = await getToken()
        let cookies = new Cookies()
        myHeaders.append("Authorization", token + cookies.get('hashCode'));
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: JSON.stringify(toDelete),
            redirect: 'follow'
        }
        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/user", requestOptions)
            .then(response => response.json())
            .then(result => {

                getAllUsers()
                setDeleteConfirmation(false)
                setLoading(false)
                setAnchorEl(null);
                setState({ openSuccess: true, openError: false, successMessage: "User deleted!" });

            })
            .catch(error => {
                console.log('error', error)
                setDeleteConfirmation(false)
                setAnchorEl(null);
                setLoading(false)
            });
    }

    const saveEdit = (u) => {
        setLoading(true)
        users.map((us) => {
            if (us.id == u.id)
                u.editView = false
        })
        setEditView(false)
        setLoading(false)
        // console.log("SAVE")

    }
    const handleChangeEvent = (e) => {
        setSelectedEvent(e.target.value)
        if (eventsList)
            eventsList.map((event) => {
                if (event.id === e.target.value)
                    setSelectedEventLabel(event.name)
            })

    }
    const handleChangeRoleFilter = (e) => {
        roles.map((r, index) => {
            if (r.id === e.target.value) {
                setSelectedRole(r.id)
                setSelectedRoleLabel(r.name)
            }
        })
    }
    const handleChangeMail = (event) => {
        setEditingEmail(event.target.value)
    };
    function handleChangeAddingMail(event) {
        setAddingEmail(event.target.value)
    };
    function handleChangeRole(event) {

        roles.map((r) => {
            if (r.id === event.target.value)
                setEditingRole(r)
        })

    };
    function handleChangeAddingRole(event) {
        roles.map((r) => {
            if (r.id === event.target.value) {
                setAddingRole(r.id)
            }
        })
    };
    const closeAddView = () => {
        setAddView(false)
        setAddingEmail('')
        setAddingRole({
            id: '0', name: 'Select Role...'
        })
    }
    const resetFilters = () => {
        setSelectedEvent(0)
        setSelectedRole(0)
        setSelectedRoleLabel('Select Role...')
        setSelectedEventLabel('Select Event...')
        setSelectedEventDialogLabel('Select Event...')
    }
    const handleSearch = (e) => {
        setSearchText(e.target.value)
    }
    const clearSearch = () => {
        setSearchText('')
    }
    const handleClick = (index, event) => {

        event.stopPropagation()
        setAnchorEl({ [index]: event.currentTarget });
    };

    const handleClose = (e) => {
        setAnchorEl(null);
        e.stopPropagation()
    };
    const handleCloseAlert = () => {
        setState({ ...state, openSuccess: false, openError: false });
    }
    const handleDefault = () => {
        setIsDefault(!is_default)
    }
    const showEvents = (u) => {
        setSelectedUser(u)
        setEventsDialog(true)

        setEventsUserList(u.events)
        setEventsUserListIDs(u.eventIDs ? u.eventIDs : [])
    }
    const getEventsByRole = (role) => {
        let val = ''
        roles.map((r) => {
            if (r.id === role)
                val = r.eventIDs
        })
        return val
    }
    const getDefaultRole = () => {
        let defaultRole = ''
        roles.map((r) => {
            if (r.isDefault)
                defaultRole = r.name
        })
        return defaultRole
    }
    const createUser = () => {
        let reg = new RegExp("[a-zA-Z]*[a-zA-Z0-9_.]+@[a-zA-Z.]+[a-zA-Z]")

        if (addingEmail === '') {
            setState({ openSuccess: false, openError: true, errorMessage: "Please insert the email!" });
        }
        else if (!addingEmail.match(reg)) {
            setState({ openSuccess: false, openError: true, errorMessage: "Please insert a valid email!" });
        }
        else if (addingRole.id === '' || addingRole.id === '0' || addingRole === '0') {
            setState({ openSuccess: false, openError: true, errorMessage: "Please select the role!" });
        }
        else {

            let def = false
            roles.map((r) => {
                if (addingRole === r.id && r.isDefault)
                    def = true
            })


            let toCreate = {}
            if (!def)
                toCreate = {
                    email: addingEmail,
                    eventIDs: null,
                    roleID: addingRole
                }
            else
                toCreate = {
                    email: addingEmail,
                    eventIDs: null
                }
            // add api

            create(toCreate)
            setAddingRole({
                id: '0', name: 'Select Role...'
            })
            setAddView(false)
            setAddingEmail('')


        }

    }
    const editUser = (u) => {

        if (editingEmail === '') {
            setState({ openSuccess: false, openError: true, errorMessage: "Please insert the email!" });
        }
        else if ((editingRole.id === '' || editingRole.id === '0' || editingRole === '0') && !is_default) {
            setState({ openSuccess: false, openError: true, errorMessage: "Please select the role!" });
        }
        else {
            //   console.log(' email ' + editingEmail + ' ruolo ' + editingRole.name)

            users.map((us) => {
                if (us.id == u.id)
                    u.editView = false
            })


            let toUpdate = {}
            if (is_default)
                toUpdate = {
                    email: editingEmail,
                    eventIDs: is_default ? null : eventsUserList,
                    id: selectedUser.id
                }
            else
                toUpdate = {
                    email: editingEmail,
                    eventIDs: is_default ? null : eventsUserList,
                    roleID: is_default ? null : editingRole.id,
                    id: selectedUser.id
                }
            setAddView(false)
            setEditingEmail('')
            setEditingRole('')
            // add api

            update(toUpdate)
            // console.log(toUpdate)
        }
    }

    async function create(toCreate) {
        setLoading(true)
        var myHeaders = new Headers();
        let token = await getToken()
        let cookies = new Cookies()
        myHeaders.append("Authorization", token + cookies.get('hashCode'));
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(toCreate),
            redirect: 'follow'
        }
        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/user", requestOptions)
            .then(response => response.json())
            .then(result => {
                setState({ openSuccess: true, openError: false, successMessage: "User created!" });
                getAllUsers()
                setLoading(false)

            })
            .catch(error => {
                console.log('error', error)
                setLoading(false)

            });
    }
    async function update(toUpdate) {
        setLoading(true)
        var myHeaders = new Headers();
        let token = await getToken()
        let cookies = new Cookies()
        myHeaders.append("Authorization", token + cookies.get('hashCode'));
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(toUpdate),
            redirect: 'follow'
        }
        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/user", requestOptions)
            .then(response => response.json())
            .then(result => {

                getAllUsers()
                setLoading(false)
                setState({ openSuccess: true, openError: false, successMessage: "User updated!" });

            })
            .catch(error => {
                console.log('error', error)
                setLoading(false)

            });
    }
    const closeEditViewUsers = (u) => {
        setLoading(true)
        getAllUsers()
        /*  let tmp = users
          tmp.map((user) => {
              if (user.id === u.id) {
                  user.editView = false
              }
          })*/
        setTimeout(() => {
            setLoading(false)
            //setUsers(tmp)
        }, 500);
    }
    const isPresent = (user_events) => {
        if (user_events) {
            let val = false
            user_events.map((e) => {
                if (e === selectedEvent)
                    val = true
            })
            return val
        }
    }
    async function getAllUsers() {
        var token = ''
        const accessTokenRequest = {

            scopes: ["user.read"],
            account: accounts[0]
        }

        await instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.idToken;
            getUsers(accessToken)
            token = accessToken
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
        })

        return token
    }

    async function getAllUsersRoles() {
        var token = ''
        const accessTokenRequest = {

            scopes: ["user.read"],
            account: accounts[0]
        }

        await instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.idToken;
            getUserRoles(accessToken)


            token = accessToken
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
        })

        return token
    }

    let roleList = roles.map((e) => <option value={e.id} selected={addingRole && addingRole.name === e.name ? true : false}>{e.isDefault ? 'Default(' : ''}{e.name} {e.isDefault ? ')' : ''}</option>)
    //let roleListEdit = roles.map((e) => <>{e.isDefault ? '' : <option value={e.id} selected={editingRole && editingRole.name === e.name ? true : false}>{e.name}</option>}</>)
    let roleListEdit = roles.map((e) => <option value={e.id} selected={editingRole && editingRole.name === e.name ? true : false}>{e.name}</option>)
    let roleItems = roles.map((e) => <MenuItem value={e.id}>{e.isDefault ? 'Default (' : ''}{e.name}{e.isDefault ? ')' : ''}</MenuItem>)


    let eventsItems = ''
    if (eventsList)
        eventsItems = eventsList.map((e) => <MenuItem value={e.id}>{e.name}</MenuItem>)


    let filteredList = 'filtered'
    filteredList = users.map((u, index) => {
        if (((searchText !== '' && u.email.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())) || searchText == '') && ((selectedRole != 0 && u.userRole && u.userRole.name === selectedRoleLabel) || selectedRole == 0) && ((selectedEvent != 0 && isPresent(u.eventIDs)) || selectedEvent == 0))
            return <div class="card_component">
                <div class="card widget-user">
                    <div >
                        <div className='top-card'>
                            <div className='flex center_align'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="black" class="bi bi-person-circle card-ico" viewBox="0 0 16 16">
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                </svg>

                                {u.editView ?
                                    <Button className={is_default ? 'red template_button pl-15 events_button_disabled' : 'red template_button pl-15 events_button'} disabled={is_default ? true : false}
                                        endIcon={<AddIcon color='red' />} onClick={() => showEvents(u)} >
                                        <div className='gucci_text_bold ml-8 '>  Events  </div>
                                    </Button> :
                                    <Button className='red template_button pl-15 events_button' endIcon={<ArrowForwardIos color='red' />} onClick={() => showEvents(u)} >
                                        <div className='gucci_text_bold ml-8 '>  Events |{u && u.eventIDs ? u.eventIDs.length : 0} </div>
                                    </Button>
                                }
                            </div>
                            {u.editView ?
                                <div className='actions_edit_user'>
                                    <Button onClick={() => editUser(u)} className="red" style={{ 'min-width': '20px', height: '35px', "margin-top": "25px", "margin-left": "-17px", "background-color": "#e9ecee", "padding-left": "11px!important" }} startIcon={<Save color='red' />} />
                                    <Button onClick={() => closeEditViewUsers(u)} className="red" style={{ 'min-width': '20px', height: '35px', "margin-top": "25px", "margin-left": "5px", "background-color": "#e9ecee", "padding-left": "11px!important" }} startIcon={<Close color='red' />} />

                                </div>
                                : <MoreVertIcon className='pointer user_dots' onClick={(e) => handleClick(index, e)} />
                            }

                            <Menu
                                anchorEl={
                                    // Check to see if the anchor is set.
                                    anchorEl && anchorEl[index]
                                }
                                keepMounted
                                open={
                                    // Likewise, check here to see if the anchor is set.
                                    Boolean(anchorEl && anchorEl[index])
                                }
                                id="menu-appbar"
                                className='menu_personale'
                                onClose={(e) => handleClose(e)}
                            >
                                <MenuItem onClick={() => setEdit(u, true)}>Edit </MenuItem>
                                <MenuItem onClick={() => deleteConfirm(u)}>Delete </MenuItem>

                            </Menu>
                        </div>
                        <div >
                            <div class="mt-20 mb-1 gucci_text_bold ml-3p fs-15">Email</div>
                            {u.editView ? <input className='email_input' type='text' value={editingEmail ? editingEmail : ''} placeholder={editingEmail ? editingEmail : ''} onChange={(e) => handleChangeMail(e)} /> : <div class="mb-10 gucci_text ml-3p fs-15">{u.email}</div>}


                            <div class="user-position">
                                <div className={u.userRole && !u.editView ? 'mt--5' : 'w-100 flex center check_container'}>
                                    {u.editView ? <div className='w-15 h-27'>
                                        <input type='checkbox' className=' toggle-button ' checked={is_default} onChange={handleDefault} />
                                    </div>
                                        : ''}
                                    {u.userRole && !u.editView ? '' : <label className={u.editView ? 'fs-15 ' : 'fs-15 mt-20'}>Default({getDefaultRole()})</label>}
                                </div>
                                <div className={u.userRole && !u.editView ? 'mt-20' : ''}>
                                    {u.editView ?
                                        <select disabled={is_default ? true : false} className={is_default ? 'event_select_disabled' : 'event_select'} onChange={(e) => handleChangeRole(e)}>{roleListEdit}</select> :
                                        <span class="text-warning capitalize "  >{u.userRole ? u.userRole.name : ''}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    })


    let list = ''
    if (users && users.length > 0)
        list = users.map((u, index) => <div class="card_component">
            <div class="card widget-user">
                <div>
                    <div className='top-card'>
                        <div className='flex center_align'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="black" class="bi bi-person-circle card-ico" viewBox="0 0 16 16">
                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                            </svg>
                            {u.editView ?
                                <Button className={is_default ? 'red template_button pl-15 events_button_disabled' : 'red template_button pl-15 events_button'}
                                    disabled={is_default ? true : false} endIcon={<AddIcon color='red' />} onClick={() => showEvents(u)} >
                                    <div className='gucci_text_bold ml-8 '>  Events  </div>
                                </Button> :
                                <Button className='red template_button pl-15 events_button' endIcon={<ArrowForwardIos color='red' />} onClick={() => showEvents(u)} >
                                    <div className='gucci_text_bold ml-8 '>  Events |{u && u.eventIDs ? u.eventIDs.length : 0} </div>
                                </Button>}
                        </div>
                        {u.editView ?
                            <div className='actions_edit_user'>
                                <Button onClick={() => editUser(u)} className="red" style={{ 'min-width': '20px', height: '35px', "margin-top": "25px", "margin-left": "-17px", "background-color": "#e9ecee", "padding-left": "11px!important" }} startIcon={<Save color='red' />} />
                                <Button onClick={() => closeEditViewUsers(u)} className="red" style={{ 'min-width': '20px', height: '35px', "margin-top": "25px", "margin-left": "5px", "background-color": "#e9ecee", "padding-left": "11px!important" }} startIcon={<Close color='red' />} />

                            </div>
                            : <MoreVertIcon className='pointer user_dots' onClick={(e) => handleClick(index, e)} />
                        }

                        <Menu
                            anchorEl={
                                // Check to see if the anchor is set.
                                anchorEl && anchorEl[index]
                            }
                            keepMounted
                            open={
                                // Likewise, check here to see if the anchor is set.
                                Boolean(anchorEl && anchorEl[index])
                            }
                            id="menu-appbar"
                            className='menu_personale'
                            onClose={(e) => handleClose(e)}
                        >
                            <MenuItem onClick={() => setEdit(u, true)}>Edit </MenuItem>
                            <MenuItem onClick={() => deleteConfirm(u)} >Delete </MenuItem>

                        </Menu>
                    </div>
                    <div >
                        <div class="mt-20 mb-1 gucci_text_bold ml-3p fs-15">Email</div>
                        {u.editView
                            ? <input className='email_input' type='text' value={editingEmail ? editingEmail : ''} placeholder={editingEmail ? editingEmail : ''} onChange={(e) => handleChangeMail(e)} /> :
                            <div class="mb-10 gucci_text ml-3p fs-15">{u.email}</div>}


                        <div class="user-position">
                            <div className={u.userRole && !u.editView ? 'mt--5' : 'w-100 flex center check_container'}>
                                {u.editView ? <div className='w-15 h-27'>
                                    <input type='checkbox' className=' toggle-button ' checked={is_default} onChange={handleDefault} />
                                </div>
                                    : ''}
                                {u.userRole && !u.editView ? '' : <label className={u.editView ? 'fs-15 ' : 'fs-15 mt-20'}>Default({getDefaultRole()})</label>}
                            </div>
                            <div className={u.userRole && !u.editView ? 'mt-20' : ''}>
                                {u.editView ?
                                    <select disabled={is_default ? true : false} className={is_default ? 'event_select_disabled' : 'event_select'} onChange={(e) => handleChangeRole(e)}>{roleListEdit}</select> :
                                    <span class="text-warning capitalize "  >{u.userRole ? u.userRole.name : ''}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >)


    let events = ''
    if (selectedUser) {
        events = eventsUserList.map((e) =>
            <div className='gucci_text custom_event_button'>
                <div className='flex'>
                    {getEventName(e)}
                    {selectedUser.editView ?
                        <svg onClick={(event) => removeEvent(event, e, selectedUser)} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ca0000" class="bi bi-x delete_event" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                        : null
                    }
                </div>
            </div>
        )
    }

    function getEventName(e) {
        let name = ''
        eventsList.map((event) => {
            if (e == event.id) {
                name = event.name
            }
        })
        return name
    }

    const handleChange = (event) => {

        if (event.target.value !== 0) {
            setLoadingDialog(true)
            setTimeout(() => {
                eventsUserListIDs.push(event.target.value)
                eventsList.map((e, index) => {
                    if (e.id === event.target.value) {
                        eventsUserList.push(e)
                    }
                })
                setEventsUserList(eventsUserList)
                setEventsUserListIDs(eventsUserListIDs)
                setLoadingDialog(false)
            }, 200)
        }
    };

    let eventsListItemsDialog_filtered = []
    let eventsListItemsDialog = []
    if (selectedUser) {
        eventsList.map((e) => {
            if (eventsUserListIDs && !eventsUserListIDs.includes(e.id)) {
                eventsListItemsDialog.push(<MenuItem value={e.id}>{e.name}</MenuItem>)
            }

        })


        //eventsListItemsDialog = <MenuItem value={0}>Select event...</MenuItem>
        //if (eventsListItemsDialog_filtered.length > 0)
        //    eventsListItemsDialog = eventsListItemsDialog_filtered.map((e) => <MenuItem value={e}>{getEventName(e)}</MenuItem>)
    }

    const removeEvent = (e) => {
        setLoadingDialog(true)
        let tmp = eventsUserList
        tmp.splice(tmp.indexOf(e), 1);
        setTimeout(() => {
            setLoadingDialog(false)
            setEventsUserList(tmp)
        }, 200);
    }


    function saveDialog() {
        setEventsDialog(false)
    }


    const handleChangeManageRole = (e) => {

        setLoadingManageRoles(true)
        let idsRole = []
        roles.map((r, index) => {
            if (r.id === e.target.value) {
                setSelectedManageRole(r.id)
                setSelectedManageRoleLabel(r.name)
                setAsDefault(r.isDefault)
                if (r.eventIDs)
                    r.eventIDs.map((id) => {
                        idsRole.push(id)
                    })
            }
        })
        setTimeout(() => {
            setListCheckedCheckbox(idsRole)
            setLoadingManageRoles(false)
        }, 200);

    }

    function isEventInRole(event) {
        let isEventInRole = false

        listCheckedCheckbox.map((e) => {
            if (event.id == e) {
                // console.log(event)
                isEventInRole = true
            }
        })


        return isEventInRole
    }


    const handleCheckboxManageRole = (e) => {
        setLoadingManageRoles(true)
        let tmp = listCheckedCheckbox
        if (!tmp.includes(e.target.value)) {
            setTimeout(() => {
                tmp.push(e.target.value)
                setListCheckedCheckbox(tmp)
                setLoadingManageRoles(false)
            }, 200);
        } else {
            setTimeout(() => {
                tmp.splice(tmp.indexOf(e.target.value), 1);
                setListCheckedCheckbox(tmp)
                setLoadingManageRoles(false)
            }, 200);
        }
    }

    async function editRole() {
        let eventsChecked = []
        listCheckedCheckbox.map((c) => {
            eventsChecked.push({
                id: c

            })
        })

        let toUpdate = {
            id: selectedManageRole,
            name: selectedManageRoleLabel,
            eventIDs: listCheckedCheckbox,
            isDefault: isDefaultRole
        }

        setLoadingManageRoles(true)
        var myHeaders = new Headers();
        let token = await getToken();
        let cookies = new Cookies()
        myHeaders.append("Authorization", token + cookies.get('hashCode'));
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(toUpdate),
            redirect: 'follow'
        }

        /**/
        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/user/role", requestOptions)
            .then(response => response.json())
            .then(result => {
                setTimeout(() => {
                    getAllUsers()
                    getAllUsersRoles()
                    setState({ openSuccess: true, openError: false, successMessage: "Role updated!" });
                    setConfirmDialog(false)
                    setSelectedManageRole(0)
                    setLoadingManageRoles(false)
                }, 200);
            })
            .catch(error => {
                console.log('error', error)
                setLoadingManageRoles(false)
                setConfirmDialog(false)
                setSelectedManageRole(0)
            });

    }


    const handleCheckboxDefaultRole = () => {
        setDefaultRoleChanged(true)
        setAsDefault(!isDefaultRole)
    }

    const handleChangePage = (bool) => {
        setSelectedManageRole(0)
        setManageRole(bool)
    }




    return (
        <div className={classes.root}>
            {loading ?
                <div style={{ textAlign: 'center' }}>
                    <Typography style={{ paddingBottom: '20px' }} variant="h6" >Loading...</Typography>
                    <CircularProgress />
                </div>
                :

                <div>

                    <Grid item xs={2} className=" mt-20" >
                        <Button className='red template_button' startIcon={<ArrowBackIosNewIcon color='red' />} onClick={() => back()} >
                            <div className='gucci_text_bold ml-8 '>  Back</div>
                        </Button>
                    </Grid>

                    <Grid container alignItems="center" justify="center" className=" mt-20">
                        <Grid item xs={12} >

                            <div className='flex'>

                                <Button className={manageRole ? 'red template_button' : 'template_button '} Style={"margin-right: 20px"} onClick={() => handleChangePage(true)} >
                                    <div className='gucci_text_bold ml-8 '>  Manage Roles</div>
                                </Button>

                                <Button className={manageRole ? 'template_button' : 'red template_button'} onClick={() => handleChangePage(false)} >
                                    <div className='gucci_text_bold ml-8 '>  Manage Users</div>
                                </Button>

                            </div>
                            <br />

                            {manageRole ?

                                <React.Fragment>
                                    <div className='flex center_align mt-10'>
                                        <FormControl style={{ minWidth: 400, "margin-right": 10 }}>
                                            <Select
                                                fullWidth
                                                className='gucci_text select_filter'
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined value-sel"
                                                value={selectedManageRole}
                                                onChange={handleChangeManageRole}
                                            >
                                                {roleItems}
                                            </Select>
                                        </FormControl>
                                        {selectedManageRole && selectedManageRole != 0 ?
                                            <div className='check_container'>
                                                <input type='checkbox' className='toggle-button-manage-roles' name="checkbox_default_role" onChange={(event) => handleCheckboxDefaultRole(event)} checked={isDefaultRole} />
                                                <label className='fs-15'>Set as default role</label>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <br />
                                    <div className="container">
                                        {loadingManageRoles ?
                                            <div style={{ textAlign: 'center' }}>
                                                <CircularProgress />
                                            </div>
                                            : (selectedManageRole && selectedManageRole != 0 ?
                                                <React.Fragment>
                                                    <FormGroup>
                                                        <div className='flex wrap'>
                                                            {eventsList.map((event) =>
                                                                event.id != 0 ?
                                                                    //<FormControlLabel control={<Checkbox defaultChecked={isEventInRole(event)} id={event.id} />} label={event.name} />
                                                                    <div className='check_container'>
                                                                        <input type='checkbox' className='toggle-button-manage-roles' name="checkbox_manage_role" value={event.id} onChange={(event) => handleCheckboxManageRole(event)} checked={isEventInRole(event)} />
                                                                        <label className='fs-15'>{event.name}</label>
                                                                    </div>
                                                                    : null
                                                            )}

                                                        </div>
                                                    </FormGroup>
                                                    <br />
                                                    <Button className={'red template_button'} Style={"margin-right: 20px"} onClick={() => setConfirmDialog(true)} >
                                                        <div className='gucci_text_bold ml-8 '>Save</div>
                                                    </Button>
                                                </React.Fragment>
                                                : null)}
                                    </div>

                                </React.Fragment>

                                :

                                <React.Fragment>
                                    {/* 
                                    <div className="flex center_align">
                                        <div className="flex center_align">
                                            <Typography className="gucci_text_bold" variant="h5" >
                                                Manage Users
                                            </Typography>
                                        </div>

                                    </div>
                                    */}
                                    <div className='search_users flex'>
                                        <div className='search_icon'>
                                            <SearchIcon />
                                        </div>
                                        <InputBase
                                            placeholder="Search user"
                                            classes={{
                                                root: 'search_user_root',
                                                input: 'search_user_input',
                                            }}
                                            value={searchText}
                                            onChange={(e) => handleSearch(e)}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                        <Button className="red" style={{ height: '40px', "margin-top": "0px", "margin-left": "25px", "background-color": "#e9ecee" }} startIcon={<ClearIcon color='red' />} onClick={clearSearch} />
                                    </div>
                                    <div className='flex center_align mt-10'>
                                        <div className='filter_by'>
                                            <div className='gucci_text_bold'>Filter by:</div>
                                        </div>
                                        <FormControl style={{ minWidth: 200, "margin-right": 10 }}>
                                            <Select
                                                fullWidth
                                                className='gucci_text select_filter'
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined value-sel"
                                                value={selectedRole}
                                                onChange={handleChangeRoleFilter}
                                            >
                                                {roleItems}
                                            </Select>
                                        </FormControl>
                                        <FormControl style={{ minWidth: 200, "margin-right": 10 }}>
                                            <Select
                                                fullWidth
                                                className='gucci_text select_filter'
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined value-sel"
                                                aria-placeholder='Select'
                                                onChange={handleChangeEvent}
                                                value={selectedEvent}
                                            >
                                                {eventsItems}
                                            </Select>
                                        </FormControl>
                                        <Button onClick={resetFilters} className="clear_search_user red" style={{ "min-width": '48px', width: '48px', height: '35px', "margin-top": "20px", "margin-left": "15px", "background-color": "#e9ecee" }} startIcon={<ClearIcon color='red' style={{ "margin-left": "0px" }} />} />
                                    </div>

                                    <div className="container">
                                        <div className='flex wrap'>
                                            {addView ? <div class="card_component">
                                                <div class="card widget-user">
                                                    <div className='top-card'>
                                                        <div className='flex center_align'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="black" class="bi bi-person-circle card-ico" viewBox="0 0 16 16">
                                                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                            </svg>

                                                            {/*        <Button className={!addingRole || addingRole === '0' || addingRole.id === '0' ? 'red template_button pl-15 events_button_disabled' : 'red template_button pl-15 events_button'}
                                                        disabled={!addingRole || addingRole === '0' || addingRole.id === '0' ? true : false} endIcon={<Add color='red' />}  >

                                                        <div className='gucci_text_bold ml-8 '>  Events  </div>
                                                    </Button>
                                */}
                                                            <div className='actions_add_user'>
                                                                <Button onClick={() => createUser()} className="red" style={{ 'min-width': '20px', height: '35px', "margin-top": "0px", "margin-left": "-17px", "background-color": "#e9ecee", "padding-left": "11px!important" }} startIcon={<Save color='red' />} />
                                                                <Button onClick={() => closeAddView(false)} className="red" style={{ 'min-width': '20px', height: '35px', "margin-top": "0px", "margin-left": "5px", "background-color": "#e9ecee", "padding-left": "11px!important" }} startIcon={<Close color='red' />} />


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-20 mb-1 gucci_text_bold ml-3p fs-15">Email</div>
                                                    <input className='email_input' type='text' placeholder={addingEmail} onChange={(e) => handleChangeAddingMail(e)} />
                                                    <div class="mb-10 gucci_text ml-3p fs-15">
                                                        <div class="user-position">
                                                            <select className='event_select' onChange={(e) => handleChangeAddingRole(e)}>{roleList}</select>
                                                            <span class="text-warning capitalize"></span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div> : <div class="card_component">
                                                <div class="card widget-user">
                                                    <div className='add_user_ico'>

                                                        <PersonAdd style={{ "font-size": '30px' }} onClick={() => setAddView(true)} />
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {searchText === '' && selectedEvent == 0 && selectedRole == 0 ? list : filteredList}
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </Grid>
                    </Grid>

                    <Dialog
                        open={openEventsDialog}
                        onClose={() => setEventsDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth="sm"
                    >

                        <DialogTitle id="alert-dialog-title"><Typography className="gucci_text_bold" variant="h5" >
                            <div className='flex'>
                                Events
                                {selectedUser && selectedUser.editView ? (
                                    showSelect ?
                                        <Button
                                            className='add_hotspot_button add_hotspot complete_button'
                                            onClick={() => setShowSelect(false)}
                                            color="primary"
                                            startIcon={<RemoveIcon color='red' />}
                                            style={{ 'margin-top': '-4px', 'margin-left': '4px' }}>
                                        </Button>
                                        :
                                        <Button
                                            className='add_hotspot_button add_hotspot complete_button'
                                            onClick={() => setShowSelect(true)}
                                            color="primary"
                                            startIcon={<AddIcon color='red' />}
                                            style={{ 'margin-top': '-4px', 'margin-left': '4px' }}>
                                        </Button>
                                )
                                    : null
                                }

                            </div>
                        </Typography></DialogTitle>

                        <DialogContent>
                            {loadingDialog ?
                                <div style={{ textAlign: 'center' }}>
                                    <CircularProgress />
                                </div>
                                :
                                <React.Fragment>
                                    <ButtonGroup variant="text" aria-label="text primary button group">
                                        <div className='button_events_container'>
                                            <div className='flex wrap'>
                                                {eventsUserList.map((e) =>
                                                    <div className='gucci_text custom_event_button'>
                                                        <div className='flex'>
                                                            {e.name}
                                                            {selectedUser.editView ?
                                                                <svg onClick={() => removeEvent(e)} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ca0000" class="bi bi-x delete_event" viewBox="0 0 16 16">
                                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                </svg>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                    </ButtonGroup>
                                    <br />
                                    {showSelect && selectedUser.editView ?
                                        <FormControl style={{ minWidth: 400, "margin-left": 5 }}>
                                            <Select
                                                fullWidth
                                                className='gucci_text select_filter'
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined value-sel"
                                                aria-placeholder='Select'
                                                onChange={handleChange}
                                                value={selectedEventDialog}
                                            >
                                                {eventsListItemsDialog}
                                            </Select>
                                        </FormControl>
                                        : null}
                                    <br />
                                </React.Fragment>
                            }
                        </DialogContent>
                        <DialogActions>
                            <div className="flex">
                                {selectedUser && selectedUser.editView ?
                                    <Button onClick={() => saveDialog()} className="template_button red" style={{ "margin-right": "10px" }}>
                                        Confirm
                                    </Button>
                                    : null
                                }

                                <Button onClick={() => setEventsDialog(false)} className="template_button">
                                    Close
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openConfirmDialog}
                        onClose={() => setConfirmDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth="sm"
                    >

                        <DialogTitle id="alert-dialog-title"><Typography className="gucci_text_bold" variant="h5" >
                            <div className='flex'>
                                Confirm
                            </div>
                        </Typography></DialogTitle>

                        <DialogContent>

                            You are editing the events related to the selected role.
                            <br />
                            {isDefaultRoleChanged && isDefaultRole ?
                                "You are setting this role and its events as default, all users with the default role will be changed, do you want to confirm?"
                                : null
                            }

                        </DialogContent>
                        <DialogActions>
                            <div className="flex">
                                <Button onClick={() => editRole()} className="template_button red" style={{ "margin-right": "10px" }}>
                                    Confirm
                                </Button>
                                <Button onClick={() => setConfirmDialog(false)} className="template_button">
                                    Close
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'rigth',
                        }}
                        open={openSuccess}
                        autoHideDuration={3000}
                        onClose={handleCloseAlert}
                        message="I love snacks"
                        key={vertical + horizontal}
                    >
                        <Alert onClose={handleCloseAlert} severity="success" dx={{ width: '300px' }}>
                            {successMessage}
                        </Alert>
                    </Snackbar>

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'rigth',
                        }}
                        open={openError}
                        onClose={handleCloseAlert}
                        autoHideDuration={3000}
                        message=""
                        key={{
                            vertical: 'bottom',
                            horizontal: 'rigth',
                        }}
                    >
                        <Alert onClose={handleCloseAlert} severity="error" dx={{ width: '300px' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={deleteConfirmation}
                        onClose={() => setDeleteConfirmation(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth="sm"
                    >

                        <DialogTitle id="alert-dialog-title">
                            <Typography className="gucci_text_bold" variant="h5" >
                                Delete
                            </Typography>
                        </DialogTitle>

                        <DialogContent>
                            {loadingDialog ?
                                <div style={{ textAlign: 'center' }}>
                                    <CircularProgress />
                                </div>
                                :
                                <React.Fragment>
                                    <div className='gucci_text'> Do you want delete the user {toDelete.email}?</div>
                                </React.Fragment>
                            }
                        </DialogContent>
                        <DialogActions>
                            <div className="flex">

                                <Button onClick={() => deleteUser(toDelete)} className="template_button red" style={{ "margin-right": "10px" }}>
                                    Confirm
                                </Button>

                                <Button onClick={() => setDeleteConfirmation(false)} className="template_button">
                                    Close
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>

                </div>

            }
        </div >
    )
}