// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: "c42c70ca-c7be-4b0d-aa00-d099020ccb5a",
        authority: "https://login.microsoftonline.com/2ff06a03-1c24-40f5-9d3b-854d93aaed7f",
        redirectUri: "https://showroom.globalbit.gucci/",
        //redirectUri: "http://localhost:3000/",

    }
};


