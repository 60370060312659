import React from 'react';
import { useMsal } from "@azure/msal-react";
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LoginIcon from '@mui/icons-material/Login';

const Login = () => {
    const { instance } = useMsal();

    const signIn = () => {
        const loginRequest = { scopes: ["openid", "profile", "User.Read"] };
        instance.loginRedirect(loginRequest)
    };

    return (<div className='login_container'>
        <div className='login_logo'>


            <div className='logo_values'>

                <div className='subtext_login gucci_text'>
                    GUCCI
                </div>
                <div className='text_login gucci_text_bold'>
                    Showroom
                </div>

                <Button className='login_button' onClick={signIn} startIcon={<LoginIcon className='login_icon' />}> Login</Button>
            </div>



        </div>
    </div>
    )
};
export default Login;