import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ProductsGrid from './ProductsGrid'
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";


import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { fade, makeStyles } from '@material-ui/core/styles';

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';


import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Cookies from 'universal-cookie';

const useStyles = makeStyles((theme) => ({

}))

export default function HotspotGrid(props) {
    const classes = useStyles();
    const [loadingHotspots, setLoadingHotspots] = React.useState(false);
    const [selectedHotspot, setSelectedHotspot] = React.useState("");
    const [openCreateHotspotDialog, setOpenCreateHotspotDialog] = React.useState(false);
    const [input, setInput] = React.useState("");
    const [products, setProducts] = React.useState([])
    const getListStyle = { display: 'flex', overflow: 'auto' }
    const { instance, inProgress, accounts } = useMsal();

    const [state, setState] = React.useState({
        openSuccess: false,
        openError: false,
        vertical: 'top',
        horizontal: 'right',
        successMessage: '',
        errorMessage: ''
    });
    const { vertical, horizontal, openSuccess, successMessage, openError, errorMessage } = state;

    const handleClose = () => {
        setState({ ...state, openSuccess: false, openError: false });
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    React.useEffect(() => {
        setSelectedHotspot("All")
        filterProducts("")
    }, [props.currentPage]);

    React.useEffect(() => {
        setSelectedHotspot("All")
        filterProducts("")
    }, [props.value]);

    React.useEffect(() => {
        setSelectedHotspot("All")
        filterProducts("")
    }, [props.searchText]);

    React.useEffect(() => {
        filterProducts(selectedHotspot)
    }, [props.display]);

    function clickHotspot(hotspot) {
        setSelectedHotspot(hotspot)
        filterProducts(hotspot)
    }

    const handleSetLoading = (bool) => {
        setLoadingHotspots(bool)
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        handleSetLoading(true)
        reorder(result.source.index, result.destination.index)
    }

    async function reorder(startIndex, endIndex) {
        // const hotspots = props.display.hotspot.sort((a, b) => parseFloat(a.position) - parseFloat(b.position))
        const hotspots = props.display.hotspots
        const result = Array.from(hotspots);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed)


        result.forEach(function (item, i) {
            var newItem = item
            newItem.position = (i + 1).toString()
            postProduct(newItem)
            props.fetchDisplays()
        })

        setTimeout(function () {
            handleSetLoading(false)
        }, 2500)
    };

    async function postProduct(item) {


        var myHeaders = new Headers();
        let cookies = new Cookies()
        let token = await aquireToken()
        myHeaders.append("Authorization", token + cookies.get('hashCode'));
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            id: item.id,
            name: item.name,
            displayID: item.displayID,
            timestamp: item.timestamp,
            position: item.position
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        /**/
        const res = await fetch("https://showroomapp.globalbit.gucci/Prod/hotspots", requestOptions)
            .then(response => response.json())
            .then(result => {
                //console.log("success post hotspot")
                //console.log(result)
            })
            .catch(error => console.log('error', error));
        return res
    }


    function filterProducts(hotspot) {
        if (hotspot === "All" || hotspot === "" | hotspot === undefined) {
            let filtered = []
            props.display.hotspots.map(h => h.products.map(p => filtered.push(p)))
            setProducts(filtered)
        } else {
            if (props.display.hotspots) {
                if (props.display.hotspots.filter(h => h.id === hotspot.id)[0]) {
                    if (props.display.hotspots.filter(h => h.id === hotspot.id)[0].products) {
                        const products = props.display.hotspots.filter(h => h.id === hotspot.id)[0].products
                        setProducts(products)
                    }
                }
            }
        }
    }

    async function aquireToken() {
        var token = ""
        const accessTokenRequest = {

            scopes: ["user.read"],
            account: accounts[0]
        }
        await instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.idToken;
            //console.log("access token: ", accessToken);
            //fetchEvents(accessToken)
            token = accessToken
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            //console.log("access token error:", error);
        })
        return token
    }


    async function createHotspot(title) {
        var myHeaders = new Headers();
        let cookies = new Cookies()
        let token = await aquireToken()
        myHeaders.append("Authorization", token + cookies.get('hashCode'));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "id": generateUniqSerial(),
            "name": title,
            "displayID": props.display.id,
            "timestamp": parseInt(+new Date() / 1000).toString(),
            "position": ""
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        }

        fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/hotspots", requestOptions)
            .then(response => response.json())
            .then(result => {
                setState({ openSuccess: true, openError: false, successMessage: "Hotspot added successfully!" });
                setOpenCreateHotspotDialog(false)
                props.fetchDisplays()
            })
            .catch(error => {
                console.log('error', error)
                setState({ openError: true, openSuccess: false, errorMessage: "Something went wrong." });
            });
    }

    async function updateHotspot(hotspot, title) {
        var myHeaders = new Headers();
        let cookies = new Cookies()
        let token = await aquireToken()
        myHeaders.append("Authorization", token + cookies.get('hashCode'));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "id": hotspot.id,
            "name": title,
            "displayID": hotspot.displayID,
            "timestamp": parseInt(+new Date() / 1000).toString(),
            "position": hotspot.position
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/hotspots", requestOptions)
            .then(response => response.json())
            .then(result => {
                setOpenCreateHotspotDialog(false)
                props.fetchDisplays()

            })
            .catch(error => console.log('error', error));
    }

    function generateUniqSerial() {
        return 'xxxx-xxxx-xxx-xxxx'.replace(/[x]/g, (c) => {
            const r = Math.floor(Math.random() * 16);
            return r.toString(16);
        });
    }

    return (
        <div style={{}}>
            <Grid item xs>

                <Typography gutterBottom variant="h5" className='gucci_text'>
                    Hotspots
                    <Button
                        className='add_hotspot_button add_hotspot complete_button'
                        onClick={() => setOpenCreateHotspotDialog(true)}
                        color="primary"
                        startIcon={<AddIcon color='red' />}
                        style={{ 'margin-top': '-4px', 'margin-left': '4px' }}>
                    </Button>
                </Typography>

                <Box m={2} />

                {loadingHotspots ?
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress />
                    </div>
                    :
                    <div style={getListStyle} className='product_list' >
                        <ButtonGroup variant="text" aria-label="text primary button group">

                            {selectedHotspot === "All" || selectedHotspot === "" ?
                                <button className='gucci_text_bold custom_hotspot_button selected_hotspot' variant="contained" onClick={() => clickHotspot("All")}>All</button >
                                :
                                <button className='gucci_text custom_hotspot_button' onClick={() => clickHotspot("All")}>All</button >
                            }

                            <DragDropContext onDragEnd={onDragEnd} >
                                <Droppable droppableId="droppable" direction="horizontal" >
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps} className="flex">

                                            {props.display.hotspots.map((hotspot, index) => (
                                                <Draggable key={hotspot.id} draggableId={hotspot.id.toString()} index={index} >
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={selectedHotspot.id === hotspot.id ? 'gucci_text_bold custom_hotspot_div selected_hotspot' : 'gucci_text custom_hotspot_div'} onClick={() => clickHotspot(hotspot)}
                                                        >{hotspot.name}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </ButtonGroup>
                    </div>
                }



                <Grid item xs={12}  >
                    <Typography variant="body2" style={{ cursor: 'pointer' }}>
                        <ProductsGrid
                            user={props.user}
                            setDisplays={props.setDisplays}
                            fetchDisplays={props.fetchDisplays}
                            clickHotspot={(hotspot) => clickHotspot(hotspot)}
                            updateHotspot={updateHotspot}
                            products={products}
                            setProducts={setProducts}
                            setLoading={props.setLoading}
                            hotspot={selectedHotspot}
                        ></ProductsGrid>
                    </Typography>
                </Grid>
            </Grid>

            <Dialog fullWidth={true} maxWidth={'sm'} open={openCreateHotspotDialog} onClose={() => setOpenCreateHotspotDialog(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create an Hotspot</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Create an Hotspot for the display named: {props.display.name}
                    </DialogContentText>
                    <TextField
                        className='text_hotspot'
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Hotspot name"
                        fullWidth
                        value={input}
                        onChange={e => setInput(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button className=' template_button' onClick={() => setOpenCreateHotspotDialog(false)} color='#ca0000' >
                        Cancel
                    </Button>
                    <Button className='red  template_button' onClick={() => createHotspot(input)} color='#ca0000'>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'rigth',
                }}
                open={openSuccess}
                autoHideDuration={3000}
                onClose={handleClose}
                message="I love snacks"
                key={vertical + horizontal}
            >
                <Alert onClose={handleClose} severity="success" dx={{ width: '300px' }}>
                    {successMessage}
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'rigth',
                }}
                open={openError}
                onClose={handleClose}
                autoHideDuration={3000}
                message=""
                key={{
                    vertical: 'bottom',
                    horizontal: 'rigth',
                }}
            >
                <Alert onClose={handleClose} severity="error" dx={{ width: '300px' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

        </div>
    )
}
