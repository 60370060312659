import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import AddNewProducts from './AddNewProducts'
import ImportLook from './ImportLook'
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import Select from '@material-ui/core/Select';

import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';

import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import Cookies from 'universal-cookie';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  img: {
    display: 'block',
    height: '50px',
  },
}));

export default function ProductsGrid(props) {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [anchorElAddProduct, setAnchorElAddProduct] = React.useState(false);
  const [openAlertDeleteHotspot, setOpenAlertDeleteHotspot] = React.useState(false);
  const [input, setInput] = React.useState("");
  const [openAlertDeleteProduct, setOpenAlertDeleteProduct] = React.useState(false);
  const [openAlertWishlistProduct, setAlertWishlistProductDialog] = React.useState(false);
  const [openFormDialog, setOpenFormDialog] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState("");
  const [openAlertChangeHotspotTitle, setOpenAlertChangeHotspotTitle] = React.useState(false);
  const [openDialogAddProducts, setOpenDialogAddProducts] = React.useState(false);
  const [openDialogImportLook, setOpenDialogImportLook] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [wishlistName, setWishlistName] = React.useState('');
  const [wishlistClient, setWishlistClient] = React.useState('');
  const [addWishlistDialog, setOpenAddWishlistDialog] = React.useState(false);
  const [selectedWishlist, setSelectedWishlist] = React.useState("0")
  const [openWishlistDialog, setOpenWishlistDialog] = React.useState(false);
  const [wishlists, setWishlists] = React.useState([]);
  const [type, setType] = React.useState("");
  const classes = useStyles();
  const getListStyle = {
    display: 'flex',
    overflow: 'auto',
    height: '310px'
  }

  const [state, setState] = React.useState({
    openSuccess: false,
    openError: false,
    vertical: 'top',
    horizontal: 'right',
    successMessage: '',
    errorMessage: ''
  });
  const { vertical, horizontal, openSuccess, successMessage, openError, errorMessage } = state;

  const handleClose = () => {
    setState({ ...state, openSuccess: false, openError: false });
  };


  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const { instance, inProgress, accounts } = useMsal();

  const onDragEnd = ({ type, destination, source }) => {
    reorder(source.index, destination.index)
  }

  const reorder = (startIndex, endIndex) => {
    const products = props.products.sort((a, b) => parseFloat(a.position) - parseFloat(b.position))

    const result = Array.from(products);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed)

    result.forEach(async function (item, i) {
      var newItem = item
      newItem.position = (i + 1).toString()
      postProduct(newItem)
    });


  };

  const handleOpenFormDialog = (bool) => {
    setOpenFormDialog(bool)
    if (!bool) {
      setWishlistName('')
      setWishlistClient('')
      setValue('')
    }
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeName = (event) => {
    setWishlistName(event.target.value)
  }

  const handleChangeClient = (event) => {
    setWishlistClient(event.target.value)
  }

  const handleAddWishlistDialog = (bool) => {
    setOpenAddWishlistDialog(bool);
  };

  const handleCloseWishlistDialog = () => {
    setOpenWishlistDialog(false);
  };

  async function addProductInWishlist() {
    handleCloseWishlistDialog(true)
    handleAddWishlistDialog(true)

    let check = checkIfAlreadyInWishlist()

    if (check) {
      setState({ openSuccess: false, openError: true, errorMessage: "The product is already in your wishlist!" });
    } else {
      //props.setLoading(true)
      var myHeaders = new Headers();
      let cookies = new Cookies()
      let token = await aquireToken()
      myHeaders.append("Authorization", token + cookies.get('hashCode'));
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id: selectedProduct.id,
        wishlistID: selectedWishlist,
        smc: selectedProduct.smc,
        position: selectedProduct.position,
        imageLink: selectedProduct.imageLink.replace("emea.guccigroup.dom", "wwmis.gucci"),
        timestamp: selectedProduct.timestamp,
        note: ''
      });


      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const res = await fetch("https://showroomapp.globalbit.gucci/Prod/wishlist/products", requestOptions)
        .then(response => response.json())
        .then(result => {
          //props.setLoading(false)
          setOpenAddWishlistDialog(false)
          setState({ openSuccess: true, openError: false, successMessage: "The product has been added in your wishlist successfully!" });
        })
        .catch(error => {
          console.log('error', error)
          setState({ openSuccess: false, openError: true, errorMessage: "Something went wrong." });
          //props.setLoading(false)
        });

      return res
    }

  }

  function checkIfAlreadyInWishlist() {
    let res = false
    wishlists.map(wishlist => {
      if (selectedWishlist == wishlist.id) {
        wishlist.products.map(product => {
          if (selectedProduct.smc == product.smc) {
            res = true
          }
        })
      }
    })
    return res
  }


  async function aquireToken() {
    var token = ""
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0]
    }
    await instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
      // Acquire token silent success
      let accessToken = accessTokenResponse.idToken;
      //console.log("access token: ", accessToken);
      //fetchEvents(accessToken)
      token = accessToken
    }).catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect(accessTokenRequest);
      }
      //console.log("access token error:", error);
    })
    return token
  }

  async function getWishlists() {
    console.log("getWishlists product grid")
    var myHeaders = new Headers();
    let accessToken = await aquireToken();
    let cookies = new Cookies()
    myHeaders.append("Authorization", accessToken + cookies.get('hashCode'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }

    const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/wishlists", requestOptions)
      .then(response => response.json())
      .then(result => {
        setWishlists(result)
      })
      .catch(error => {
        console.log('error', error)
      });

    return res
  }

  async function postProduct(item) {
    var myHeaders = new Headers();
    let cookies = new Cookies()
    let token = await aquireToken()
    myHeaders.append("Authorization", token + cookies.get('hashCode'));
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(item);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/products", requestOptions)
      .then(response => response.json())
      .then(result => {
        ///  console.log(result)
      })
      .catch(error => console.log('error', error));
  }

  async function deleteHotspot(hotspost) {
    var myHeaders = new Headers();
    let cookies = new Cookies()
    let token = await aquireToken()
    myHeaders.append("Authorization", token + cookies.get('hashCode'));
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "id": hotspost.id
    });
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/hotspots", requestOptions)
      .then(response => response.json())
      .then(result => {
        props.fetchDisplays()
        props.clickHotspot("All")
        setState({ openSuccess: true, openError: false, successMessage: "Hotspot deleted successfully!" });
        setOpenAlertDeleteHotspot(false)
        setAnchorEl(null)
      })
      .catch(error => {
        console.log('error', error)
        setState({ openError: true, openSuccess: false, errorMessage: "Something went wrong." });
      });

  }

  async function deleteProduct(product) {
    var myHeaders = new Headers();
    let cookies = new Cookies()
    let token = await aquireToken()
    myHeaders.append("Authorization", token + cookies.get('hashCode'));
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "id": product.id
    });
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/products", requestOptions)
      .then(response => response.json())
      .then(result => {
        setOpenAlertDeleteProduct(false)
        setState({ openSuccess: true, openError: false, successMessage: "Product deleted successfully!" });
        props.fetchDisplays()
      })
      .catch(error => {
        console.log('error', error)
        setState({ openError: true, openSuccess: false, errorMessage: "Something went wrong." });
      });
  }


  async function addWishlist() {

    handleOpenFormDialog(false)
    props.setLoading(true)

    var myHeaders = new Headers();
    let token = await aquireToken();
    let cookies = new Cookies()
    myHeaders.append("Authorization", token + cookies.get('hashCode'));
    myHeaders.append("Content-Type", "application/json");

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = dd + '/' + mm + '/' + yyyy;

    let generatedId = uuidv4()

    var raw = JSON.stringify({
      id: '',
      name: wishlistName,
      lastEdit: today,
      note: value,
      client: wishlistClient,
      products: [],
      timestamp: parseInt(+new Date() / 1000).toString()
    });

    /**/
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    }

    const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/wishlists", requestOptions)
      .then(response => response.json())
      .then(result => {
        //getWishlist(token)

        var raw = JSON.stringify({
          id: selectedProduct.id,
          wishlistID: generatedId,
          smc: selectedProduct.smc,
          position: selectedProduct.position,
          imageLink: selectedProduct.imageLink.replace("emea.guccigroup.dom", "wwmis.gucci"),
          timestamp: selectedProduct.timestamp,
          note: ''
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        const res2 = fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/wishlist/products", requestOptions)
          .then(response => response.json())
          .then(result => {
            setState({ openSuccess: true, openError: false, successMessage: "Wishlist added successfully!" });
          })
          .catch(error => {
            setState({ loading: false, openError: true, openSuccess: false, errorMessage: "Something went wrong." });
          });
      })
      .catch(error => {
        //console.log('error', error)
        setState({ loading: false, openError: true, openSuccess: false, errorMessage: "Something went wrong." });
      });


    props.setLoading(false)
    return res
  }

  function changeHotspotTitle(hotspotId, input) {
    setOpenAlertChangeHotspotTitle(false)
    setAnchorEl(null)
    props.updateHotspot(props.hotspot, input)
  }

  function addProducts(type) {
    setType(type)
    setOpenDialogAddProducts(true)
    setAnchorElAddProduct(null)
  }


  function importLook() {
    setOpenDialogImportLook(true)
    setAnchorElAddProduct(null)
  }


  function openAlertDeleteProductDialog(product) {
    setSelectedProduct(product)
    setOpenAlertDeleteProduct(true)
  }

  function openAlertWishlistProductDialog(product) {
    setSelectedProduct(product)
    setAlertWishlistProductDialog(true)
  }

  function addProductToWishlist() {
    setAlertWishlistProductDialog(false)
    handleAddWishlistDialog(true)
  }

  async function newWishlist() {
    handleAddWishlistDialog(false)
    handleOpenFormDialog(true)
  }

  async function backDialog() {
    handleAddWishlistDialog(true)
    handleOpenFormDialog(false)
  }

  return (
    <div className={classes.root}>
      <Box m={2} />
      <div style={{ display: 'flex' }}>


        <Typography className='gucci_text_bold' variant="h5" >Products({props.products.length}) - {props.hotspot === "" || props.hotspot === "All" ? "All" : props.hotspot.name} </Typography>
        {props.hotspot === "" || props.hotspot === "All" ? null :
          <div className='flex hotspot_button'>
            <Button
              onClick={e => setAnchorElAddProduct(e.currentTarget)}
              color="primary"
              className='complete_button'
              startIcon={<AddIcon />}>
            </Button>

            <Button aria-controls="simple-menu"
              aria-haspopup="true"
              color="primary"
              className='complete_button'
              startIcon={<MoreVertIcon />}
              onClick={e => setAnchorEl(e.currentTarget)}>
            </Button>
          </div>
        }
      </div>

      <Box m={1} />
      {props.hotspot === "" || props.hotspot === "All" ?

        <div className='product_list' style={getListStyle}>

          {props.products.sort((a, b) => parseFloat(a.position) - parseFloat(b.position)).map((p, index) => (
            <div>
              <div style={{ textAlign: 'center', width: '300px' }}>
                <img
                  style={{ height: '100px' }}
                  src={p.imageLink.replace("emea.guccigroup.dom", "wwmis.gucci")}
                />

                <Typography className='gucci_text' gutterBottom variant="subtitle1">
                  SMC: {p.smc}
                  <IconButton onClick={() => { navigator.clipboard.writeText(p.smc) }} aria-label="delete" className={classes.margin}>
                    <ContentCopyIcon />
                  </IconButton>
                </Typography>
                <Typography className='gucci_text' gutterBottom variant="subtitle1">
                  SKU: {p.sku}
                </Typography>
                <Typography gutterBottom variant="subtitle1">
                </Typography>
                <IconButton onClick={() => openAlertDeleteProductDialog(p)} aria-label="delete" className={classes.margin}>
                  <DeleteIcon className='delete_button' />
                </IconButton>
                <IconButton onClick={() => openAlertWishlistProductDialog(p)} aria-label="delete" className={classes.margin}>
                  {p.isInWishlist ? <StarIcon className='add_wishlist_button' /> : <StarOutlineIcon className='add_wishlist_button' />}

                </IconButton>
              </div>
            </div>
          ))}
        </div>

        :

        <DragDropContext onDragEnd={onDragEnd} >
          <Droppable droppableId="droppable" direction="horizontal" >
            {(provided, snapshot) => (

              <div style={getListStyle} ref={provided.innerRef} {...provided.droppableProps} className='product_list'>

                {props.products.sort((a, b) => parseFloat(a.position) - parseFloat(b.position)).map((p, index) => (
                  <Draggable key={p.id} draggableId={p.id.toString()} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div style={{ textAlign: 'center', width: '300px' }}>

                          <img
                            style={{ height: '100px' }}
                            src={p.imageLink.replace("emea.guccigroup.dom", "wwmis.gucci")}
                          />

                          <Typography className='gucci_text' gutterBottom variant="subtitle1">
                            SMC: {p.smc}
                            <IconButton onClick={() => { navigator.clipboard.writeText(p.smc) }} aria-label="delete" className={classes.margin}>
                              <ContentCopyIcon />
                            </IconButton>

                          </Typography>
                          <Typography className='gucci_text' gutterBottom variant="subtitle1">
                            SKU: {p.sku}
                          </Typography>

                          <Typography className='gucci_text' gutterBottom variant="subtitle1">
                            {"Order:" + p.position}
                          </Typography>
                          <IconButton onClick={() => openAlertDeleteProductDialog(p)} aria-label="delete" className={classes.margin} >
                            <DeleteIcon className='delete_button' />
                          </IconButton>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

      }
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => setOpenAlertChangeHotspotTitle(true)}>Change Title</MenuItem>
        <MenuItem onClick={() => setOpenAlertDeleteHotspot(true)}>Delete Hotspot</MenuItem>
      </Menu>

      <Menu
        anchorEl={anchorElAddProduct}
        keepMounted
        open={Boolean(anchorElAddProduct)}
        onClose={() => setAnchorElAddProduct(null)}
      >
        <MenuItem onClick={() => addProducts("SMC")}>Add SMC</MenuItem>
        {/* <MenuItem onClick={() => addProducts("SKU")}>Type SKU</MenuItem> */}
        <MenuItem onClick={() => importLook()}>Import Look</MenuItem>
      </Menu>

      <Dialog
        open={openAlertDeleteHotspot}
        onClose={() => setOpenAlertDeleteHotspot(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Hotspot elimination"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the hotspot named: {props.hotspot.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlertDeleteHotspot(false)} className="template_button">
            Cancel
          </Button>
          <Button onClick={() => deleteHotspot(props.hotspot)} className=" red template_button" autoFocus>
            DELETE
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAlertDeleteProduct}
        onClose={() => setOpenAlertDeleteProduct(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Product elimination"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the product with smc: {selectedProduct.smc}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlertDeleteProduct(false)} className="template_button">
            Cancel
          </Button>
          <Button onClick={() => deleteProduct(selectedProduct)} className="template_button red" autoFocus>
            DELETE
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openAlertWishlistProduct}
        onClose={() => setAlertWishlistProductDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add product to wishlist</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to add the product with smc {selectedProduct.smc} to your wishlist?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertWishlistProductDialog(false)} className="template_button" >
            Cancel
          </Button>
          <Button onClick={() => addProductToWishlist(selectedProduct)} className="template_button red" autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth={true} maxWidth={'sm'} open={openAlertChangeHotspotTitle} onClose={() => setOpenAlertChangeHotspotTitle(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change the Hotspos title</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Change the title of the hotspot named {props.hotspot.name}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New Hotspot name"
            fullWidth
            value={input}
            onChange={e => setInput(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlertChangeHotspotTitle(false)} className='template_button'>
            Cancel
          </Button>
          <Button onClick={() => changeHotspotTitle(props.hotspot.id, input)} className=' red template_button' >
            Change
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openFormDialog}
        onClose={() => handleOpenFormDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title"><Typography className="gucci_text_bold" variant="h5" >
          New wishlist
        </Typography></DialogTitle>
        <DialogContent>
          <TextField id="wishlist_name" label="Name your wishlist" variant="standard" onChange={(e) => { handleChangeName(e) }} />
          <div id='client_new_wishlist_dialog'>
            <TextField id="wishlist_client" label="Client name" variant="standard" onChange={(e) => { handleChangeClient(e) }} />
          </div>
          <div id='notes_new_wishlist_dialog'>
            <TextField
              id="standard-multiline-flexible"
              label="Notes"
              multiline
              maxRows={4}
              value={value}
              onChange={handleChange}
              variant="standard"
              fullWidth
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => backDialog()} className="back_button template_button">
            Back
          </Button>
          <Button onClick={() => handleOpenFormDialog(false)} className="template_button">
            Cancel
          </Button>
          <Button onClick={() => addWishlist()} className="red template_button" autoFocus>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addWishlistDialog}
        onClose={() => handleAddWishlistDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Add products to your wishlist
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the wishlist to add the products to
          </DialogContentText>
          <Select
            fullWidth
            className='gucci_text'
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined value-sel"
            value={selectedWishlist}
            onChange={e => setSelectedWishlist(e.target.value)}
            label="Select a wishlist"
          >
            <MenuItem value="0" >Select a wishlist</MenuItem>
            {wishlists.map(w =>
              <MenuItem value={w.id}>
                {w.name}
              </MenuItem>
            )}
          </Select>
          <br />
          <br />
          <DialogContentText>
            or create a
            <Button onClick={() => newWishlist()} className="red">
              new wishlist
            </Button>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleAddWishlistDialog(false)} className="template_button">
            cancel
          </Button>
          <Button onClick={() => addProductInWishlist()} className="red template_button" color="primary" autoFocus>
            add
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'rigth',
        }}
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleClose}
        message=""
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity="success" dx={{ width: '300px' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'rigth',
        }}
        open={openError}
        onClose={handleClose}
        autoHideDuration={3000}
        message=""
        key={{
          vertical: 'bottom',
          horizontal: 'rigth',
        }}
      >
        <Alert onClose={handleClose} severity="error" dx={{ width: '300px' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <AddNewProducts
        type={type}
        fetchDisplays={props.fetchDisplays}
        fetchProducts={props.clickHotspot}
        hotspot={props.hotspot}
        setAnchorElAddProduct={setAnchorElAddProduct}
        openDialogAddProducts={openDialogAddProducts}
        setOpenDialogAddProducts={setOpenDialogAddProducts}
      ></AddNewProducts>

      <ImportLook
        fetchDisplays={props.fetchDisplays}
        clickHotspot={props.clickHotspot}
        fetchProducts={props.fetchProducts}
        hotspot={props.hotspot}
        openDialogImportLook={openDialogImportLook}
        setOpenDialogImportLook={setOpenDialogImportLook}
        setAnchorElAddProduct={setAnchorElAddProduct}>
      </ImportLook>
    </div>
  );
}