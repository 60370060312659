import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { color } from '@mui/system';
import Cookies from 'universal-cookie';

const AddNewProducts = (props) => {
    const [input, setInput] = useState("");
    const [items, setItems] = useState([]);
    const [searching, setSearching] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState(false);
    const { instance, inProgress, accounts } = useMsal();

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            height: 400,
            maxWidth: 300,
            backgroundColor: theme.palette.background.paper,
        },
    }))

    const classes = useStyles();

    function openAlert(message) {
        setOpen(true)
        setMessage(message)
    }

    function fetchDataBySMC(smc) {

        let formattedSMC = smc.slice(0, 6) + "-" + smc.slice(6, 11) + "-" + smc.slice(11, 15)
        let imgLink = "http://getimage.emea.guccigroup.dom/getimage/?Cod=" + formattedSMC

        const newItem = {
            id: generateUniqSerial(),
            name: "N/A",
            hotspostID: props.hotspot.id,
            sku: "N/A",
            smc: smc,
            imageLink: imgLink,
            timestamp: parseInt(+new Date / 1000).toString(),
            createdBy: "",
            visibilityColorWeb: true,
            visibilityBrandExperience: true,
            position: ""

        }
        setItems([...items, newItem])
    }

    function fetchDataBySKU() {
        setSearching(true)
        var axios = require('axios');
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        var qs = require('qs');
        var data = qs.stringify({
            'bID': 'com.gucci.corporate.ccms-vtmap'
        });
        var config = {
            method: 'post',
            url: '/asshadow/api/?getSTYLECOLORbySKU=' + input,
            headers: {
                'Authorization': '4ae1d3b0-f76f-4d76-a21f-16106a126132',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: data
        }
        axios(config)
            .then(function (response) {
                const message = response.data.Message
                if (message != "Ok") {
                    openAlert(message)
                }
                const style = response.data.SKUs[0].Model
                const material = response.data.SKUs[0].Material
                const color = response.data.SKUs[0].Color
                const smc = style + material + color
                fetchDataBySMC(smc)
            })
            .catch(function (error) {
                setSearching(false)
                openAlert(error.toString())
                console.log("Response Error... : ", error);
            });
    }



    async function saveProducts() {
        items.forEach(async function (item) {
            aquireToken(item)
        });
        props.setOpenDialogAddProducts(false)
        props.fetchDisplays()
        setItems([])
    }

    async function aquireToken(item) {
        const accessTokenRequest = {
            scopes: ["user.read"],
            account: accounts[0]
        }
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.idToken;
            //console.log("access token: ", accessToken);
            postProduct(item, accessToken)
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            //console.log("access token error:", error);
        })
    }

    async function postProduct(item, accessToken) {
        var myHeaders = new Headers();
        let cookies = new Cookies()

        myHeaders.append("Authorization", accessToken + cookies.get('hashCode'));
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(item);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/products", requestOptions)
            .then(response => response.json())
            .then(result => {
            })
            .catch(error => console.log('error', error));
    }

    function generateUniqSerial() {
        return 'xxxx-xxxx-xxx-xxxx'.replace(/[x]/g, (c) => {
            const r = Math.floor(Math.random() * 16);
            return r.toString(16);
        });
    }

    return (
        <div>

            <Dialog open={props.openDialogAddProducts} onClose={() => props.setOpenDialogAddProducts(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add new Products by {props.type}
                    <Button style={{ position: "absolute", right: "10px" }} onClick={() => saveProducts()} className='red template_button'>Save</Button>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.type === "SMC" ? "Please lengthen this text to 15 characters." : "Please lengthen this text to 10 characters."}
                    </DialogContentText>

                    <TextField
                        autoFocus
                        margin="dense"
                        label={props.type === "SMC" ? "Type Style Material Color" : "Type SKU"}
                        fullWidth
                        value={input}
                        onChange={e => setInput(e.target.value)}
                    />

                    <List style={{ maxHeight: 200, overflow: 'auto' }}>
                        {searching ? <CircularProgress /> : null}
                        {items.map((i) => (
                            <ListItemText primary={i.smc} />
                        ))}
                    </List>


                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.setOpenDialogAddProducts(false)} className=' template_button'>
                        Cancel
                    </Button>

                    <Button onClick={() => { props.type === "SMC" ? fetchDataBySMC(input) : fetchDataBySKU() }} className='red template_button'>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"An error occurred while searching"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" autoFocus>OK</Button>
                </DialogActions>
            </Dialog>
        </div>

    );
};

export default AddNewProducts
