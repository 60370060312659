import { Button, ButtonBase, CircularProgress, Grid, makeStyles, Menu, MenuItem, Paper, Typography } from "@material-ui/core";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import { useMsal } from "@azure/msal-react";
import React, { useRef } from 'react';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import AddIcon from '@material-ui/icons/Add';


import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@mui/material/TextField';
import ImageZoom from "react-medium-image-zoom";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import { iconButtonClasses } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';

const useStyles = makeStyles((theme) => ({
    root: {
        'margin-top': '95px',
        'margin-left': '25px',
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'grid',
        margin: '15px',
        width: '96%',
        height: '-webkit-available'
    },
    image: {
        width: '120px',
        height: '-webkit-fill-available',
        alignItems: 'center',
        justify: 'center',
        'margin-bottom': '5px',
        'max-height': '76px',
        'margin-top': '10px',
        'min-width': '80px',
        'transform': 'scale(0.9)'
    }
}));
export default function WishlistDetail(props) {
    const classes = useStyles()
    const inputEl = useRef(iconButtonClasses);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openMenuDialog, setOpenMenuDialog] = React.useState(false);
    const [input, setInput] = React.useState("");
    const [productNote, setProductNote] = React.useState("");
    const [selectedProduct, setSelectedProduct] = React.useState("");
    const [searching, setSearching] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [openFormDialog, setOpenFormDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [productSMC, setProductSMC] = React.useState('');

    const [state, setState] = React.useState({
        openSuccess: false,
        openError: false,
        vertical: 'top',
        horizontal: 'right',
        successMessage: '',
        errorMessage: ''
    });
    const { vertical, horizontal, openSuccess, successMessage, openError, errorMessage } = state;

    async function back() {
        props.setWishlistDetail(false)
    }

    const { instance, inProgress, accounts } = useMsal();

    React.useEffect(() => {
        aquireToken()
    }, [instance, accounts, inProgress])

    const handleOpenFormDialog = (bool) => {
        setOpenFormDialog(bool)
    }

    const handleCloseSnackbar = () => {
        setState({ ...state, openSuccess: false, openError: false });
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    async function downloadPdf() {
        props.setLoading(true)
        var myHeaders = new Headers();
        let cookies = new Cookies()
        let accessToken = await aquireToken()
        myHeaders.append("Authorization", accessToken + cookies.get('hashCode'));
        let item = {
            id: props.selectedWishlist.id,
            assistat: props.selectedWishlist.client
        }
        var raw = JSON.stringify(item);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const res = await fetch("https://showroompdf.globalbit.gucci/Prod/wishlistpdf", requestOptions)
            .then(response => response.json())
            .then(result => {
                /*decrypt base64* /
                let binaryString = window.atob(result.body);
                let binaryLen = binaryString.length;
                let bytes = new Uint8Array(binaryLen);
                for (let i = 0; i < binaryLen; i++) {
                    let ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                }
                //download
                const link = document.createElement('a')
                const blob = new Blob([bytes], { type: 'application/pdf' })
                link.href = window.URL.createObjectURL(blob)
                link.download = 'products.pdf'
                link.click() */

                const link = document.createElement('a')
                link.href = result.path
                link.click()

                props.setLoading(false)
            })
            .catch(error => {
                props.setLoading(false)
            });
        return res
    }

    const handleOpenEditDialog = (bool, product) => {
        setAnchorEl(null);
        setOpenEditDialog(bool)
        if (product) {
            setProductNote(product.note)
            setSelectedProduct(product)
        }
    }

    const handleChangeName = (event) => {
        setProductSMC(event.target.value)
    }

    const handleClose1 = (e) => {
        setAnchorEl(null);
        e.stopPropagation()
    };

    const handleClick1 = (index, event) => {
        event.stopPropagation()
        setAnchorEl({ [index]: event.currentTarget });
    };


    async function saveProducts() {
        /*
        items.forEach(async function (item) {
            aquireToken(item)
        });*/
        handleOpenFormDialog(false)
        //props.fetchDisplays()
        setItems([])
    }

    async function aquireToken() {

        var token = ''
        const accessTokenRequest = {

            scopes: ["user.read"],
            account: accounts[0]
        }

        await instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.idToken;
            props.setLoading(true)
            getWishlistDetails(accessToken)

            token = accessToken
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
        })

        return token
    }

    async function getWishlistDetails(accessToken) {
        var myHeaders = new Headers();
        let cookies = new Cookies()
        myHeaders.append("Authorization", accessToken + cookies.get('hashCode'));
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/wishlist/products?id=" + props.selectedWishlist.id, requestOptions)
            .then(response => response.json())
            .then(result => {
                setProducts(result)
                props.setLoading(false)
            })
            .catch(error => {
                console.log('error', error)
                props.setLoading(false)
            });

        return res
    }

    async function aquireToken2() {

        var token = ''
        const accessTokenRequest = {

            scopes: ["user.read"],
            account: accounts[0]
        }

        await instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.idToken;
            token = accessToken
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
        })

        return token
    }

    async function searchingSMC() {

        var myHeaders = new Headers();
        let token = await aquireToken2();
        let cookies = new Cookies()
        myHeaders.append("Authorization", token + cookies.get('hashCode'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/products", requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
            })
            .catch(error => console.log('error', error));


    }

    async function addProduct() {

        /*
        searchingSMC()
        */
        var myHeaders = new Headers();
        let token = await aquireToken2();
        let cookies = new Cookies()
        myHeaders.append("Authorization", token + cookies.get('hashCode'));
        myHeaders.append("Content-Type", "application/json");

        let smc = input
        let linkString = smc.slice(0, 6) + "-" + smc.slice(6, 11) + "-" + smc.slice(11, 15)
        let generatedId = uuidv4()
        var raw = JSON.stringify({
            id: generatedId,
            wishlistID: props.selectedWishlist.id,
            smc: input,
            position: "",
            imageLink: 'http://getimage.wwmis.gucci/getimage/?Cod=' + linkString,
            timestamp: parseInt(+new Date() / 1000).toString(),
            note: ''
        });

        const newItem = {
            id: generatedId,
            wishlistID: props.selectedWishlist.id,
            smc: input,
            position: "",
            imageLink: 'http://getimage.wwmis.gucci/getimage/?Cod=' + linkString,
            timestamp: parseInt(+new Date() / 1000).toString(),
            note: ''
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/wishlist/products", requestOptions)
            .then(response => response.json())
            .then(result => {
                setItems([...items, newItem])
                setProducts([...products, newItem])
                setSearching(false)
                setState({ openSuccess: true, openError: false, successMessage: "Product added successfully!" });
            })
            .catch(error => {
                console.log('error', error)
                props.setLoading(false)
                setSearching(false)
                setState({ openError: true, openSuccess: false, errorMessage: "Something went wrong." });
            });

        return res

    }

    async function removeProduct(product) {

        /**/
        setAnchorEl(null);
        props.setLoading(true)
        var myHeaders = new Headers();
        let token = await aquireToken2();
        let cookies = new Cookies()
        myHeaders.append("Authorization", token + cookies.get('hashCode'));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            id: product.id,
        });

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/wishlist/products", requestOptions)
            .then(response => response.json())
            .then(result => {
                getWishlistDetails(token)
                setState({ openSuccess: true, openError: false, successMessage: "Product deleted successfully!" });
            })
            .catch(error => {
                console.log('error', error)
                props.setLoading(false)
                setState({ openError: true, openSuccess: false, errorMessage: "Something went wrong." });
            });

        return res

    }

    async function editProductNote() {
        props.setLoading(true)
        var myHeaders = new Headers();
        let token = await aquireToken2();
        let cookies = new Cookies()
        myHeaders.append("Authorization", token + cookies.get('hashCode'));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            id: selectedProduct.id,
            wishlistID: selectedProduct.wishlistID,
            smc: selectedProduct.smc,
            position: selectedProduct.position,
            imageLink: selectedProduct.imageLink,
            timestamp: selectedProduct.timestamp,
            note: productNote
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const res = await fetch("https://8gq358vrq0.execute-api.eu-west-1.amazonaws.com/Prod/wishlist/products", requestOptions)
            .then(response => response.json())
            .then(result => {
                getWishlistDetails(token)
                handleOpenEditDialog(false, [])
            })
            .catch(error => {
                console.log('error', error)
                props.setLoading(false)
                handleOpenEditDialog(false, [])
            });

        return res
    }

    function formatDate(string) {
        var options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(string).toLocaleDateString([], options);
    }

    function getWishListProducts() {
        let wishlistProducts = 'Nessun prodotto presente'
        if (products.length > 0) {
            wishlistProducts = products.map((w, index) =>
                <div className="mt-15 flex template_product_wishlist pl-15" key={index}>
                    <div className="self_center">
                        <ButtonBase className={classes.image} >
                            <ImageZoom
                                image={{
                                    src: w.imageLink.replace("emea.guccigroup.dom", "wwmis.gucci"),
                                    // className: classes.image,
                                    style: { "max-height": '130px' }
                                }}
                                zoomImage={{
                                    src: w.imageLink.replace("emea.guccigroup.dom", "wwmis.gucci"),
                                    alt: ''
                                }}
                            />
                        </ButtonBase>
                    </div>
                    <div className="wishlist_product_details">
                        <div className="gucci_text_bold ml-8"> SMC: {w.smc}</div>
                        <div className="gucci_text ml-8"> Added to Wishlist: {formatDate(Date(w.timestamp))}</div>
                        <div className="gucci_text ml-8 ellipsis-2"> Note:  {w.note ? w.note : '-'}</div>
                    </div>
                    <div className="remove_product_wishlist flex">
                        <Button
                            ref={inputEl}
                            aria-owns={openMenuDialog ? 'menu-product' : null}
                            aria-haspopup="true"
                            onClick={(e) => handleClick1(index, e)}

                            color="primary"
                            className='complete_button remove_icon'
                            startIcon={< MoreVertIcon />}>
                        </Button>
                        <Menu
                            anchorEl={
                                // Check to see if the anchor is set.
                                anchorEl && anchorEl[index]
                            }
                            keepMounted
                            open={
                                // Likewise, check here to see if the anchor is set.
                                Boolean(anchorEl && anchorEl[index])
                            }
                            onClose={(e) => handleClose1(e)}
                            id="menu-product"

                        >
                            <MenuItem onClick={() => removeProduct(w)}>Remove Product</MenuItem>
                            <MenuItem onClick={() => handleOpenEditDialog(true, w)}>Add Note</MenuItem>

                        </Menu>
                    </div>

                </div>


            )
        }
        return wishlistProducts
    }

    return (
        <div className={classes.root}>
            {props.loading ?
                <div style={{ textAlign: 'center' }}>
                    <Typography style={{ paddingBottom: '20px' }} variant="h6" >Loading...</Typography>
                    <CircularProgress />
                </div>
                :
                <div>
                    <Grid item xs={2} className="ml-20" >
                        <Button className='red template_button pl-15' startIcon={<ArrowBackIosNewIcon color='red' />} onClick={() => back()} >
                            <div className='gucci_text_bold ml-8 '>  Back</div>
                        </Button>
                    </Grid>
                    <div className="gucci_text_bold mt-8 ml-8">
                        <Grid container alignItems="center" justify="center"  >
                            <Grid item xs={12} >
                                <Paper className={classes.paper}>
                                    <div className="flex">
                                        <div className="flex center_align">
                                            <LocalMallIcon className="wishlist_icon" />
                                            <Typography className="gucci_text_bold capitalize" variant="h5" >
                                                {props.selectedWishlist.name}
                                            </Typography>
                                        </div>
                                        <div className='hotspot_button left_align flex'>
                                            <Button
                                                onClick={() => handleOpenFormDialog(true)}
                                                color="primary"
                                                className='wishlist_option_button'
                                                startIcon={<AddIcon />}>
                                            </Button>
                                            <Button
                                                onClick={() => downloadPdf(true)}
                                                color="primary"
                                                className='wishlist_option_button'
                                                startIcon={<PictureAsPdfIcon />}>
                                            </Button>
                                        </div>

                                    </div>
                                    <div className="mt-15 flex wrap">
                                        {getWishListProducts()}
                                    </div>
                                </Paper>

                            </Grid>
                        </Grid>
                    </div>



                    <Dialog open={openFormDialog} onClose={() => handleOpenFormDialog(false)} aria-labelledby="form-dialog-title"
                        aria-describedby="alert-dialog-description" fullWidth>
                        <DialogTitle id="form-dialog-title">Add new Products to your wishlist
                            <Button style={{ position: "absolute", right: "10px" }} onClick={() => saveProducts()} className='red template_button'>Save</Button>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please lengthen this text to 15 characters.
                            </DialogContentText>

                            <TextField
                                autoFocus
                                label={"Type Style Material Color"}
                                fullWidth
                                variant="standard"
                                value={input}
                                onChange={e => setInput(e.target.value)}
                            />

                            <List style={{ maxHeight: 200, overflow: 'auto' }}>
                                {searching ? <CircularProgress /> : null}
                                {items.map((i) => (
                                    <ListItemText key={i.smc} primary={i.smc} />
                                ))}
                            </List>


                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleOpenFormDialog(false)} className=' template_button'>
                                Cancel
                            </Button>

                            <Button onClick={() => addProduct(input)} className='red template_button'>
                                Add
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openEditDialog} onClose={() => handleOpenEditDialog(false)} aria-labelledby="form-dialog-title" fullWidth>
                        <DialogTitle id="form-dialog-title">Add note to your product</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                label="Type your note here"
                                fullWidth
                                variant="standard"
                                value={productNote}
                                onChange={e => setProductNote(e.target.value)}
                            />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleOpenEditDialog(false)} className=' template_button'>
                                Cancel
                            </Button>

                            <Button onClick={() => editProductNote()} className='red template_button'>
                                Add
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'rigth',
                        }}
                        open={openSuccess}
                        autoHideDuration={3000}
                        onClose={handleCloseSnackbar}
                        message=""
                        key={vertical + horizontal}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="success" dx={{ width: '300px' }}>
                            {successMessage}
                        </Alert>
                    </Snackbar>

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'rigth',
                        }}
                        open={openError}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={3000}
                        message=""
                        key={{
                            vertical: 'bottom',
                            horizontal: 'rigth',
                        }}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="error" dx={{ width: '300px' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>

                </div>}
        </div>
    )
}